/*# sourceURL=_button.scss */
.btn {
  border-radius: var(--btn-border-radius);
}


.btn-w-m {
  min-width: 120px;
}

.btn-primary.btn-outline {
  color: #2e5676;
}

.btn-success.btn-outline {
  color: #1c84c6;
}

.btn-info.btn-outline {
  color:  #59d3d3;
}

.btn-warning.btn-outline {
  color: var(--yellow);
}

.btn-danger.btn-outline {
  color: #ed5565;
}

.btn-danger.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-warning.btn-outline:hover {
  color: #fff;
}

.btn-primary {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: #FFFFFF;
}

.btn-primary.active,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary:focus,
.btn-primary:hover,
.open .dropdown-toggle.btn-primary {
  // background-color: darken(#2e5676, 3%);
  // border-color: darken(#2e5676, 3%);
  background-color: var(--IboxBg);
  border-color: var(--IboxBg);
  color: #FFFFFF;
}

.btn-primary.active,
.btn-primary:active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.active[disabled],
.btn-primary.disabled,
.btn-primary.disabled.active,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled],
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: lighten(#2e5676, 4%);
  border-color: lighten(#2e5676, 4%);
}

.btn-success {
  background-color: #4099ad;
  border-color: #4099ad;
  color: #FFFFFF;
}

.btn-success.active,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active,
.btn-success:active:focus,
.btn-success:active:hover,
.btn-success:focus,
.btn-success:hover,
.open .dropdown-toggle.btn-success {
  background-color: darken(#1c84c6, 3%);
  border-color: darken(#1c84c6, 3%);
  color: #FFFFFF;
}

.btn-success.active,
.btn-success:active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.active[disabled],
.btn-success.disabled,
.btn-success.disabled.active,
.btn-success.disabled:active,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled],
.btn-success[disabled]:active,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success.active,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
  background-color: lighten(#1c84c6, 4%);
  border-color: lighten(#1c84c6, 4%);
}

.btn-info {
  background-color:  #59d3d3;
  border-color:  #59d3d3;
  color: #FFFFFF;
}

.btn-info.active,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active,
.btn-info:active:focus,
.btn-info:active:hover,
.btn-info:focus,
.btn-info:hover,
.open .dropdown-toggle.btn-info {
  background-color: darken( #59d3d3, 3%);
  border-color: darken( #59d3d3, 3%);
  color: #FFFFFF;
}

.btn-info.active,
.btn-info:active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.active[disabled],
.btn-info.disabled,
.btn-info.disabled.active,
.btn-info.disabled:active,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled],
.btn-info[disabled]:active,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info.active,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
  background-color: lighten( #59d3d3, 4%);
  border-color: lighten( #59d3d3, 4%);
}

.btn-default {
  color: inherit;
  background: white;
  border: 1px solid  #ddd
}

.btn-default.active,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active,
.btn-default:active:focus,
.btn-default:active:hover,
.btn-default:focus,
.btn-default:hover,
.open .dropdown-toggle.btn-default {
  color: inherit;
  border: 1px solid #d2d2d2;
}

.btn-default.active,
.btn-default:active,
.open .dropdown-toggle.btn-default {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-default.active[disabled],
.btn-default.disabled,
.btn-default.disabled.active,
.btn-default.disabled:active,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled],
.btn-default[disabled]:active,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
  color: #cacaca;
}

.btn-warning {
  background-color: var(--yellow);
  border-color: var(--yellow);
  color: #FFFFFF;
}

.btn-warning.active,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.btn-warning:focus,
.btn-warning:hover,
.open .dropdown-toggle.btn-warning {
  background-color: darken(#ffc107, 3%);
  border-color: darken(#ffc107, 3%);
  color: #FFFFFF;
}

.btn-warning.active,
.btn-warning:active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.active[disabled],
.btn-warning.disabled,
.btn-warning.disabled.active,
.btn-warning.disabled:active,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled],
.btn-warning[disabled]:active,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning.active,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
  background-color: lighten(#ffc107, 4%);
  border-color: lighten(#ffc107, 4%);
}

.btn-danger {
  background-color: #ed5565;
  border-color: #ed5565;
  color: #FFFFFF;
}

.btn-danger.active,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.btn-danger:focus,
.btn-danger:hover,
.open .dropdown-toggle.btn-danger {
  background-color: darken(#ed5565, 3%);
  border-color: darken(#ed5565, 3%);
  color: #FFFFFF;
}

.btn-danger.active,
.btn-danger:active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.active[disabled],
.btn-danger.disabled,
.btn-danger.disabled.active,
.btn-danger.disabled:active,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled],
.btn-danger[disabled]:active,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger.active,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
  background-color: lighten(#ed5565, 4%);
  border-color: lighten(#ed5565, 4%);
}

.btn-link {
  color: inherit;
}

.btn-link.active,
.btn-link:active,
.btn-link:focus,
.btn-link:hover,
.open .dropdown-toggle.btn-link {
  color: #2e5676;
  text-decoration: none;
}

.btn-link.active,
.btn-link:active,
.open .dropdown-toggle.btn-link {
  background-image: none;
}

.btn-link.active[disabled],
.btn-link.disabled,
.btn-link.disabled.active,
.btn-link.disabled:active,
.btn-link.disabled:focus,
.btn-link.disabled:hover,
.btn-link[disabled],
.btn-link[disabled]:active,
.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link.active,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
  color: #cacaca;
}

.btn-white {
  color: inherit;
  background: white;
  border: 1px solid  #ddd
}

.btn-white.active,
.btn-white.active:focus,
.btn-white.active:hover,
.btn-white:active,
.btn-white:active:focus,
.btn-white:active:hover,
.btn-white:focus,
.btn-white:hover,
.open .dropdown-toggle.btn-white {
  color: inherit;
  border: 1px solid #d2d2d2;
}

.btn-white.active,
.btn-white:active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-white.active,
.btn-white:active,
.open .dropdown-toggle.btn-white {
  background-image: none;
}

.btn-white.active[disabled],
.btn-white.disabled,
.btn-white.disabled.active,
.btn-white.disabled:active,
.btn-white.disabled:focus,
.btn-white.disabled:hover,
.btn-white[disabled],
.btn-white[disabled]:active,
.btn-white[disabled]:focus,
.btn-white[disabled]:hover,
fieldset[disabled] .btn-white,
fieldset[disabled] .btn-white.active,
fieldset[disabled] .btn-white:active,
fieldset[disabled] .btn-white:focus,
fieldset[disabled] .btn-white:hover {
  color: #cacaca;
}

.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
  box-shadow: none;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}

.btn-rounded {
  border-radius: 50px;
}

.btn-large-dim {
  width: 120px;
  height: 100px;
  font-size: 42px;
}

button.dim {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding-top: 6px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px !important;
}

button.dim:active {
  top: 3px;
}

button.btn-primary.dim {
  box-shadow: inset 0 0 0 darken(#2e5676, 6%), 0 5px 0 0 darken(#2e5676, 6%), 0 10px 5px #999;
}

button.btn-primary.dim:active {
  box-shadow: inset 0 0 0 darken(#2e5676, 6%), 0 2px 0 0 darken(#2e5676, 6%), 0 5px 3px #999;
}

button.btn-default.dim {
  box-shadow: inset 0 0 0 darken(#dfdfdf, 6%), 0 5px 0 0 darken(#dfdfdf, 6%), 0 10px 5px #999;
}

button.btn-default.dim:active {
  box-shadow: inset 0 0 0 darken(#dfdfdf, 6%), 0 2px 0 0 darken(#dfdfdf, 6%), 0 5px 3px #999;
}

button.btn-warning.dim {
  box-shadow: inset 0 0 0 darken(#ffc107, 6%), 0 5px 0 0 darken(#ffc107, 6%), 0 10px 5px #999;
}

button.btn-warning.dim:active {
  box-shadow: inset 0 0 0 darken(#ffc107, 6%), 0 2px 0 0 darken(#ffc107, 6%), 0 5px 3px #999;
}

button.btn-info.dim {
  box-shadow: inset 0 0 0 darken( #59d3d3, 6%), 0 5px 0 0 darken( #59d3d3, 6%), 0 10px 5px #999;
}

button.btn-info.dim:active {
  box-shadow: inset 0 0 0 darken( #59d3d3, 6%), 0 2px 0 0 darken( #59d3d3, 6%), 0 5px 3px #999;
}

button.btn-success.dim {
  box-shadow: inset 0 0 0 darken(#1c84c6, 6%), 0 5px 0 0 darken(#1c84c6, 6%), 0 10px 5px #999;
}

button.btn-success.dim:active {
  box-shadow: inset 0 0 0 darken(#1c84c6, 6%), 0 2px 0 0 darken(#1c84c6, 6%), 0 5px 3px #999;
}

button.btn-danger.dim {
  box-shadow: inset 0 0 0 darken(#ed5565, 6%), 0 5px 0 0 darken(#ed5565, 6%), 0 10px 5px #999;
}

button.btn-danger.dim:active {
  box-shadow: inset 0 0 0 darken(#ed5565, 6%), 0 2px 0 0 darken(#ed5565, 6%), 0 5px 3px #999;
}

button.dim:before {
  font-size: 50px;
  line-height: 1em;
  font-weight: normal;
  color: #fff;
  display: block;
  padding-top: 10px;

}

button.dim:active:before {
  top: 7px;
  font-size: 50px;
}

.btn:focus {
  outline: none !important;
}
