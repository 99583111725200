/*# sourceURL=_rtl.scss */

.body.rtls {
  #page-wrapper {
    margin: 0 240px 0 0;
  }

  .nav>li>a {
    padding: 10px 20px 10px 5px !important;
  }

  .drop a {
    display: inline-block;
  }

  .icons-list {
    border: 1px solid #59d3d3;
    top: 64px;
    left: -3px;
    right: 33px;
  }

  .d-padding {
    padding: 0;
  }

  .icons-btn-wrapper {
    right: auto;
    left: -11px;
  }

  .flex-content {
    text-align: left;
  }

  .flex-table td,
  .flex-table th {
    justify-content: left;
  }

  .selected-status-img {
    margin-right: 20px;
  }

  .moz-width {
    padding-left: 3px;
  }

  .time-do {
    padding-left: 25px;
  }

  .company {
    right: 40px;
  }

  .ibox-tab-tools {
    left: 15px;
  }

  .icons {
    left: -10px;
  }

  .btn-reject {
    position: relative;
    width: 100%;
    max-width: 118px;
    margin-top: 12px;
    right: 0 !important;
  }

  .search-code {
    position: relative;
  }

  [class*=card-]>a {
    flex-direction: row;
  }

  .changeOptionsView {
    position: relative;
    right: 10px;
  }

  .host-text {
    left: 0;
  }

  .d-flex,
  .p-l-m-l {
    padding-left: 10px;
  }

  .img-position {
    left: 0;
  }

  .search-icon-menu {
    left: 0;
  }

  .phone-dropdown-menu {
    right: 0;
  }

  .search-code-remove {
    top: 9px;
    left: 12px;
  }

  .phone-dropdown-menu>li {
    text-align: right;
  }

  .m-r-rtl {
    margin-left: 20px;
  }

  .btn-wrapper {
    right: auto;
    left: 16px;
  }

  .nav-label {
    margin-right: 15px;
  }

  .btn-position {
    right: -10px;
    // top: 14px;
  }

  .error-distance {
    padding-left: 22px;
    padding-right: 12px;
    margin-right: 24px;
    z-index: 1;
    background: #fff;
  }

  .error-distance::before {
    top: 5px;
    left: 128px;
  }

  .input-tag-rtl {
    padding-right: 60px !important;
  }

  .filt {
    float: right;
  }

  .col-pading-left {
    padding-left: 0;
    margin-left: -20px;
  }

  .btm-borderNone {
    margin-left: 10px;
  }

  .btn-remove>img {
    right: 186px;
  }

  .social-buttons {
    left: 0px !important;
  }

  .form-rtl {
    margin-top: 60px;
  }

  .input-group-addon-rtl {
    position: absolute;
    left: 0 !important;
    right: auto !important;
    top: 26px;
  }

  .button-group-partnerGallery {
    left: auto;
    min-width: 25px;
    margin-left: 0;
    margin-top: 0;
    right: -4px;
  }

  .btn-pos {
    border-radius: 0;
  }

  .btn-create {
    display: flex;
    flex-direction: row-reverse;
  }

  .button-eye-profil {
    right: auto;
    left: 0;
  }

  .drop-rtl {
    margin-left: 0 !important;
  }

  .t-rotate {
    transform: rotate(90deg) translate(80px, 0);
  }

  .group {
    display: block;
  }

  .titlePos {
    display: flex;
    justify-content: flex-end;
    position: relative !important;
  }

  .fileUpload-remove {
    position: relative;
    float: left;
    cursor: pointer;
    margin-top: 7px;
    margin-left: 55px;
  }

  .p-l-r-15 {
    padding-right: 7px;
  }

  .radioinput[type="radio"]+.radio-label[_ngcontent-xim-c16]:before {
    margin-left: 10px;
  }

  .radio-ingredient-group-rtl {
    right: 130px;
    margin-top: 6px !important;
  }

  .m-l-70 {
    margin-right: 35px;
  }

  .label-flex-rtl {
    display: flex;
  }

  .gtext {
    margin-right: 15px;
  }

  .type-group {
    left: 188px;
  }

  .pull-rtl {
    float: left !important;
  }

  .m-t-60 {
    left: 15px;
  }

  .cards-center {
    position: relative;
    left: auto;
    right: 50%;
  }

  .sorts {
    float: left;
  }

  .btn-helps-hover {
    margin-left: -50px;
    margin-top: 16px;
  }

  .setdate {
    float: left;
    margin-top: 7px;
    display: flex;
    flex-direction: row-reverse;
  }

  .title:after,
  .title:before {
    right: auto;
    left: 20px;
    top: 1.35em !important;
  }

  .order-grid-m-t-rtl {
    margin-top: 0;
    margin-left: 6px;
  }

  table th {
    text-align: right !important;
  }

  .nav-tabs>.active {
    border: none;
  }

  .tab-content>.active {
    border: none;
  }

  .iframe-video {
    margin-top: 0 !important;
  }

  .buttonspos {
    display: table;
    margin: 0 auto;
  }

  .card__content {
    width: 84%;
  }

  .social-buttons {
    padding: 3px;
  }

  .text-rtl {
    text-align: center !important;
  }

  .currency-codes-wrappers {
    right: 90px;
  }

  table th {
    text-align: right;
  }

  //log itme rtl
  .wrappers-others {
    justify-content: flex-end;
    margin-left: 10px;
    right: auto !important;
  }

  .d-flex-icon-rtl {
    display: flex;
    justify-content: flex-start;
  }

  [class*="card-"] h1,
  [class*="card-"] h2 {
    text-align: left;
    margin-left: 20px;
    width: auto !important;
  }

  .description-rtl {
    text-align: inherit !important;
  }

  .description-catalog-rtl {
    text-align: left;
    margin-left: 20px;
  }

  [class*="card-"] .flex-content p:last-child {
    margin-bottom: 15px !important;
    text-align: flex-end;
    // margin-left: 20px;
    justify-content: flex-end;
  }

  [class*="card-"] p.user {
    margin-bottom: 17px;
    text-align: flex-end;
    margin-left: 14px;
  }

  [class*="card-"] .flex-content ul {
    margin-left: 10px !important;
  }

  [class*="card-"],
  [class*="card-"]>a {
    flex-direction: row-reverse;
  }

  [class*="card-"]:nth-child(odd) .img-wrappers {
    padding-left: 32px !important;
    flex: 1 1 210px !important;
  }

  ////

  .all {
    top: -44px !important;
    margin-left: 25px !important;
  }

  .seeAll {
    position: absolute;
    left: 90px;
    margin-top: -5px;
    right: auto;
  }

  .price-main-rtl {
    position: relative !important;
    padding-right: 15px !important;
    right: -72px !important;
    padding-left: 0;
  }

  .volume {
    margin-right: 0 !important;
    margin-left: 16px !important;
  }

  .btn-lang {
    right: auto;
    left: 40%;
  }

  #sidebar {
    left: 0 !important;
    right: auto !important;
  }

  .p-30-rtl {
    padding-left: 0;
    padding-right: 30px;
  }

  .wrappers-img {
    right: auto;
    left: 20px;
  }

  .rec-item-name {
    top: 0;
    position: relative;
    right: 52px;
    width: 150px;
  }

  .m-r-31 {
    margin-right: -31px;
  }

  .h4 {
    padding-right: 40px;
  }

  .phone-title {
    right: 0;
  }

  input[type="checkbox"]+label:before {
    top: 16px;
  }

  .overlay-group-rtl {
    right: 20px !important;
  }

  .ingridient-item-name {
    left: auto;
    position: absolute;
    top: 9px;
    right: 53px;
  }

  .ingridient-remove {
    right: auto;
    left: 0;
  }

  .card-description-rec {
    padding-left: 15px;
  }

  .card-description-ingridient {
    padding-left: 15px;
  }

  .card_img-rtl {
    left: 0 !important;
  }

  .rec-remove {
    right: auto;
    left: 0;
  }

  .add-info-title {
    margin-right: 30px;
  }

  .buttonspos__btn {
    float: left;
  }

  #tabs a {
    float: right !important;
  }

  .generate-svg-icon {
    right: 5px;
    top: 0;
  }

  .rtl-disp {
    padding-right: 10px;
  }

  .btn-gr {
    position: absolute;
    left: 0;
    padding-right: 5px;
    right: auto;
  }

  .btn-gr-rtl {
    float: left;
    margin-left: 25px;
    position: relative;
  }

  .tree-view {
    overflow: auto;
    height: calc(100vh - 350px);
    margin-left: 0;
  }

  .font-qrTree {
    margin-left: -12px;
  }

  .count-scan {
    float: right !important;
  }

  .select-div {
    margin-right: 28px;
  }

  .drop-btn-tree {
    min-width: 91px;
    padding: 4px;
    margin-top: 0;
    top: 0;
    right: -105px;
    left: 50px;
  }

  .i-w-rtl {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  .l-w {
    margin-bottom: 0 !important;
  }

  .employee-iamges-rtl {
    float: left;
  }

  .input-checkbox-employee[type="checkbox"]:checked+label:before {
    top: 0 !important;
    left: 40px !important;
  }

  .input-checkbox-employee[type="checkbox"]+label:before {
    left: 28px !important;
    top: 5px !important;
  }

  .treeArrow {
    font-size: 24px;
    margin-top: 16px;
    position: absolute;
    right: -15px;
  }

  .item-name-rtl {
    // width: 100px !important;
    margin-right: 8px !important;
  }

  .input-group-qr-group-rtl {
    margin-right: 33px;
    margin-top: 19px;
    margin-bottom: 7px;
  }

  .all-qr-rtl {
    top: -58px;
    margin-left: 15px;
  }

  .btn-toppanel-open {
    position: relative !important;
    float: left;
  }

  .nav-header {
    left: auto;
    right: 0;
  }

  .font-notif {
    left: 50px;
    position: relative;
  }

  .top-user-btn {
    line-height: 0;
  }

  .btn-top-leng {
    margin-top: 0;
  }

  .leng-dropdown-svg {
    margin-left: 0;
    position: absolute;
    left: 0;
    top: 36px;
    right: 10px;
  }

  .imgFlag {
    margin-right: 19px;
  }

  .languages-name {
    position: relative;
    top: 0;
    right: 100px;
    z-index: 9;
  }

  .select-top-lang-rtl {
    margin-top: -5px;
  }

  .all-log-rtl {
    position: static;
    margin-top: -47px;
    margin-left: 11px;
    margin-bottom: 38px;
    font-size: 19px;
  }

  .save {
    left: 0 !important;
  }

  .d-flex>p {
    margin-left: 0 !important;
  }

  .text-muted {
    margin-top: 5px;
  }

  .ibox-tools-icon-m-list-rtl {
    float: none !important;
  }

  .showAttachedQrs {
    border-radius: 0 0 100px 0;
    left: -15px;
  }

  .autoleng-title-left {
    text-align: right;
  }

  .directory-home-icon {
    position: absolute;
    right: 80%;
  }

  .drop-down-freeTrial {
    right: -62px !important;
  }

  .btn-group-all {
    right: 0;
    left: auto !important;
    position: -webkit-sticky;
    position: sticky;
  }

  .en-order {
    margin-right: 30px;
    margin-left: 0;
  }

  .qrTreees>li {
    margin-left: 0 !important;
    margin-right: 35px !important;
  }

  .def-lang {
    right: 0;
  }

  .qrTree-node {
    margin-right: 26px !important;
  }

  .m-t-4 {
    margin-right: -30px !important;
  }

  .box-description {
    text-align: center;
    font-size: 36px !important;
  }

  .paymentMethod-column-rtl-lefts {
    width: auto;
  }

  .uparrowpos {
    top: -10px;
    left: -14px;
  }

  .downarrowpos {
    top: 8px;
  }

  .nav {
    padding-right: 0;
  }

  .input-advertisement-rtl {
    margin: 0 4px 29px !important;
    padding-right: 13px !important;
  }

  .hot-title {
    justify-content: normal;
  }

  .label-advertisement-rtl {
    left: 0;
    right: 6px;
  }

  .topBtnadd {
    margin-top: 0;
  }

  .paymentMethod-column-rtl-rights {
    width: auto;
  }

  .files {
    padding-right: 46px;
  }

  .price-rtl {
    text-align: center;
  }

  .tubBtn__container {
    left: -76px !important;
    float: right;
  }

  .f-15 {
    text-align: center;
  }

  .material_block_payment {
    padding: 11px !important;
  }

  .qr-config-btn {
    padding-left: 17px;
  }

  .tabs-container .nav-tabs>li {
    float: right !important;
  }

  .dropdown-menu-fliter-rtl {
    left: 12%;
  }


  .h2-f-s {
    margin-top: 20px;
  }

  .btn-save>p {
    text-align: center;
  }

  .text-center-qr-rtl {
    text-align: center !important;
  }

  .editable-title-text-center>p {
    text-align: center !important;
  }

  .standard-title-text-center>p {
    text-align: center !important;
  }

  .w-250 {
    padding-right: 18px;
    width: 100%;
    max-width: 264px;
  }

  .editable-title {
    text-align: center !important;
  }

  .standard-title {
    text-align: center !important;
  }

  .mini-ul {
    position: relative;
  }

  .blocks_1 {
    text-align: center;
  }

  .ingridient-btn-gropu {
    margin-right: 70px;
  }

  .catalog-name {
    display: flex;
    justify-content: flex-end;
  }

  .input-rtl {
    padding-right: 20px !important;
  }

  .other-input {
    margin: 8px auto !important;
  }

  .line {
    left: -6px;
    position: relative;
  }

  .overlay-rtl {
    left: 0;
    right: auto;
    margin-right: 0;
    margin-left: -6px;
    -moz-margin-start: 0;
    -moz-margin-end: -6px;
  }

  .active-order-wrappers-rtl {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl-body-m {
    padding: 15px;
  }

  .rtl-croper {
    height: calc(50vh + 50px);
  }

  .uploder-text {
    text-align: center !important;
  }

  .btn-bitbucket {
    left: -15px;
    position: relative;
  }

  // .d-flex>p {
  //   margin-right: 30px;
  // }

  .checkoutType-text {
    padding-right: 10px;
  }

  .hoverBottomAdd-rtl {
    position: relative;
    top: 48px;
    margin-left: 0;
    left: -30px;
    margin-bottom: 0;
  }

  .form-group-close {
    margin-bottom: -12px;
    z-index: 1;
    margin-top: 35px;
  }

  // .ibox-tools-print-rtl {
  //   right: auto;
  //   left: 4px;
  // }
  .order_number_time_box {
    text-align: right;
    margin-left: 0;
  }

  .trackingNumber {
    margin-left: 0;
    float: right;
    margin-bottom: 20px;
    margin-right: 18px;
  }

  .title-top-rtl {
    width: 50%;
    // left: 45%;
    left: 0;
  }

  .btn-center-open-createproduct-rtl {
    left: auto;
  }

  .header-right {
    float: left;
  }

  .delet-rtl {
    left: 7px;
  }

  .activeOrder-grid-rtl {
    left: 0 !important;
  }

  .svg-icon-left {
    margin: 6px;
    left: 7px;
    position: relative;
    top: -9px;
    float: right !important;
  }

  .svg-bookmeker {
    left: 0;
  }

  .svg-status-left {
    position: relative;
    right: 85%;
  }

  .list-group-item-activeOrder {
    right: 25% !important;
  }

  .setCheckout-btn {
    float: left;
    cursor: pointer;
    width: 43.5%;
    position: relative;
    top: 30px;
    border-radius: 0;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    left: auto;
  }

  .header-activeOrder-rtl {
    height: 51px !important;
  }

  .client-titles-rtl {
    width: 100px !important;
  }

  .chart-div {
    left: -9px !important;
    width: 55%;
    margin-top: 20px;
  }

  select {
    background: #fff !important;
  }

  .svg-icon-dropdown {
    left: -220px;
    z-index: 5;
  }

  .svg-icon-dropdown-rtl-qr {
    left: -1px;
    z-index: 5;
    right: 9px;
    margin: -1px 5px -4px 13px;
  }

  .svg-icon-back-rtl {
    transform: rotate(180deg);
  }

  .action-btn-svg {
    display: block;
    margin: 0 auto;
  }

  .min-width-th {
    min-width: auto !important;
  }

  // .switch-cont {
  //   float: left;
  // }

  .catalog-group-scroll {
    margin-left: 0;
    margin-right: 0;
  }

  .currenc-arrow-rtl {
    right: auto;
    left: 14px;
  }

  .div-input-small {
    flex-direction: row-reverse;
  }

  .m-b-none {
    text-align: right;
  }

  .m-xs-rtl {
    left: 30px;
    right: auto;
  }

  .order-stotl-two {
    bottom: 41px;
    right: -20px;
    font-size: 16px;
    float: left;
  }

  .dropdown-menu-qr-rtl {
    left: -75px !important;
  }

  .d-icon-editable {
    margin-left: 15px;
  }

  .d-icon-standard {
    margin-left: 15px;
  }

  .input-group-btn:first-child>.btn,
  .input-group-btn:first-child>.btn-group {
    margin-right: 0;
  }

  .p-l {
    padding-right: 20px !important;
    // padding-left: 0 !important;
  }

  .pull-left-themeMobile-rtl {
    float: right;
  }

  .pull-left-QR-rtl {
    float: right;
  }

  .svg-icon-upload {
    width: 37px;
    height: 30px;
    left: 5px;
    position: relative;
    top: 6px;
  }

  .select-arrow-qrcodecreate-rtl {
    float: left;
    position: relative;
    top: -30px;
    transform: rotate(-90deg);
    right: -7px;
  }

  select {
    padding-right: 23px;
  }

  .input-number-decrement,
  .input-number-increment {
    background: #fff;
    border: 0;
    border-right: 1px solid #bbd0dd !important;
    cursor: pointer;
    display: block;
    outline: none;
    position: absolute;
    text-align: center;
    right: 40px;
    margin: -3px 4px;
  }

  .select-arrow-rtl-theme {
    background: none;
    right: -0;
  }

  .select-arrow-rtl-creatproduct-brand {
    right: 0 !important;
    float: left;
    left: auto !important;
    top: -50px !important;
    margin-left: 15px;
  }

  .select-arrow-rtl-creatproduct-state {
    right: 240px;
    top: 11px !important;
    float: left;
    left: auto !important;
  }

  .select-arrow-rtl-chart {
    left: 30px !important;
    right: auto !important;
  }

  .select-arrow-rtl-profiel-currency {
    left: 30px !important;
    right: auto !important;
  }

  .select-arrow-rtl-generalInfo {
    right: 90% !important;
  }

  .select-arrow-rtl-qrUpdate {
    right: 95% !important;
  }

  .slect-images-rtl {
    float: left;
  }

  .scannText {
    display: block;
  }

  .rtl-left-nav {
    left: 0;
  }

  // .btn-position {
  //   top: 46px;
  // }

  .dropdown-menu-log-rtl {
    left: -6px !important;
  }

  .dropdown-menu-notificationList-rtl {
    left: 120px;
    right: auto;
  }

  .ordringlsitcalendar--1 {
    right: 120px;
    left: 0 !important;
  }

  .dropdown-menu-lang-rtl {
    left: 231px !important;
    right: auto;
  }

  .svg-icon-dropdown {
    left: -11px !important;
    z-index: 5;
  }

  .dot-top {
    left: 13% !important;
  }

  .li-flex {
    justify-content: space-between;
  }


  @media (max-width: 1366px) and (min-width: 768px) {
    .list-group-languages-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .item-name {
      width: 108px !important;
    }

    .input-group-left {
      right: 0 !important;
    }

    .small-new {
      left: calc(21px + 0.5vw);
      bottom: 22px;
    }

    .d__flex {
      right: 80px !important;
    }

    .icon-wrappers {
      left: 160px !important;
    }

    .item-name {
      width: 108px !important;
    }

    .input-group-left {
      right: 0 !important;
    }

    .d__flex {
      right: 80px;
    }

    .icon-wrappers {
      left: 160px !important;
    }

    .list-group-languages-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .select-arrow-rtl-creatproduct-state {
      right: 75% !important;
    }

    .search-icon-m-list {
      position: relative !important;
      height: 26px;
      margin-top: 9px;
      top: -14px !important;
      right: 44px !important;
    }

    .all-btn-grid {
      display: grid;
      margin: 0 auto;
      justify-content: flex-end;
      position: absolute;
      bottom: 25px;
      grid-gap: 14px;
      left: -70px !important;
    }

    .d-flex-icon {
      padding-left: 15px !important;
    }

    .languages-fix-paretn-container {
      right: 111px;
    }

    .small-group {
      width: 45%;
    }

    .dropdown-menu-log-rtl {
      left: -5px !important;
    }

    .p-l {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .group-left-cont {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .desc {
      position: relative;
      left: -20px !important;
      padding: 0 !important;
      top: 0;
    }

    .setImagePos {
      height: 40px;
      width: 43px;
      right: 25%;
      float: right;
      position: relative;
    }

    .item-name {
      left: 100px !important;
    }

    #page-wrapper {
      margin: 0;
    }

    .btn-toppanel-open {
      left: auto !important;
      right: 0 !important;
    }

    .p-l {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .group-top {
      width: 100% !important;
    }

    .dropdown-menu-lang-rtl {
      right: auto;
      left: calc(10% + 4.3vw);
      width: calc(67% + 1.5vw);
    }

    .input-group-search-rtl {
      top: 10px !important;
      margin: 0 auto;
      display: table;
      width: calc(300px + 6vw);
    }

    .svgTop {
      width: 50%;
      margin: 0 auto;
      top: 34px;
      position: relative;
      left: 20%;
      height: 100%;
    }

    .dropdown-menu-translatelanguages {
      width: calc(100% - 41px);
      left: 0;
      height: 250px;
      overflow: auto;
      margin: 0 auto 0 -77px;
      top: 127px;
    }

    .dropdown-menu-baselanguages {
      width: calc(100% - 41px);
      left: 0;
      height: 250px;
      overflow: auto;
      margin: 0 auto 0 -77px;
      top: 127px;
    }

    .d__flex {
      right: 120px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .search-icon-m-list {
      position: relative !important;
      height: 26px;
      margin-top: 9px;
      top: -14px !important;
      right: 44px !important;
    }

    .select-arrow-rtl-creatproduct-state {
      right: 75% !important;
    }

    .dropdown-menu-baselanguages {
      width: calc(100% - 41px);
      left: 0;
      height: 250px;
      overflow: auto;
      margin: 0 auto 0 -77px;
      top: 127px;
    }

    .dropdown-menu-translatelanguages {
      width: calc(100% - 41px);
      left: 0;
      height: 250px;
      overflow: auto;
      margin: 0 auto 0 -77px;
      top: 127px;
    }

    .input-group-search-rtl {
      top: 10px !important;
      margin: 0 auto;
      display: table;
      width: calc(300px + 6vw);
    }

    .all-btn-grid {
      display: grid;
      margin: 0 auto;
      justify-content: flex-end;
      position: absolute;
      bottom: 25px;
      grid-gap: 14px;
      left: -70px !important;
    }

    .languages-fix-paretn-container {
      right: 111px;
    }

    .dropdown-menu-log-rtl {
      left: -5px !important;
    }

    .small-group {
      width: 45%;
    }

    .p-l {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .group-left-cont {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .d-flex-icon {
      padding-left: 15px !important;
    }

    .desc {
      position: relative;
      left: -20px !important;
      padding: 0 !important;
      top: 0;
    }

    .setImagePos {
      height: 40px;
      width: 43px;
      right: 25%;
      float: right;
      position: relative;
    }

    .item-name {
      left: auto !important;
    }

    .group-top {
      width: 100% !important;
    }

    .p-l {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    #page-wrapper {
      margin: 0 240px 0 0;
    }

    .btn-toppanel-open {
      left: auto !important;
      right: 0 !important;
    }

    .dropdown-menu-lang-rtl {
      right: auto;
      left: calc(10% + 4.3vw);
      width: calc(67% + 1.5vw);
    }

    .d__flex {
      right: 120px !important;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .tabs-container .nav-tabs>li {
      float: left !important;
    }

    .input-number-decrement,
    .input-number-increment {
      right: 53px !important;
    }

    .header a {
      float: left;
    }

    .mor-text-m-b {
      margin-bottom: 30px;
      font-size: 15px;
      font-weight: 700;
    }

    .searchbtn-menulist-rtl {
      right: -30px !important;
    }

    .blocks-mobile {
      left: auto !important;
      right: 63px !important;
    }

    .input-daterange {
      right: 40px !important;
      left: auto !important;
    }

    .svg-icon-dropdown {
      left: -11px !important;
      z-index: 5;
    }

    .input-qrFrameupadate-rtl {
      width: 100%;
      float: right;
      max-width: 210px;
    }

    .select-arrow-qrcodecreate-rtl {
      float: left;
      position: relative;
      top: -30px;
      transform: rotate(-90deg);
      right: 0;
    }

    .searchbtn-rtl-qr {
      position: relative;
      outline: none;
      z-index: 1;
      color: #fff;
      font-size: 15px;
      right: -135px !important;
      float: left;
      top: -56px !important;
    }

    .custom-dropdown select {
      width: 100%;
      max-width: 300px;
    }

    .tags-display-grid {
      margin-left: 2px;
      margin-right: -6px;
    }

    .p-r-30 {
      padding-right: 30px;
      padding-left: 0;
    }

    .svg-icon-dropdown {
      left: -220px !important;
      z-index: 5;
    }

    .svgStyleTree {
      width: 70% !important;
      margin: 0 auto;
      top: 30px !important;
      position: relative;
    }

    .svgTop {
      width: 80% !important;
    }

    .header-right {
      float: none !important;
    }

    .p-r-theme-rtl {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .switch input~ :checked~.toggle {
      left: 28px !important;
    }

    .add-fromexist>.svg-icon {
      left: -30px !important;
    }

    .add-fromexist {
      height: 60px;
      width: 100%;
      bottom: 0;
      outline: none;
      padding: 0;
      border-radius: 0;
      position: relative;
      top: 73px;
      display: block;
      margin: 0 !important;
      background: none !important;
      border: 1px solid #d2d2d2 !important;
      max-width: 200px;
      right: 78px;
    }

    .input-group-div {
      z-index: 2;
    }

    .selectedVariables {
      right: 0;
      top: auto !important;
      bottom: -6px !important;
    }

    .calcdiv-set-price-input {
      width: 100%;
      right: 0 !important;
      bottom: 60px !important;
      top: auto !important;
    }

    .product-option-group-rtl {
      display: block;
      padding-right: 50px !important;
      padding-bottom: 0 !important;
      float: right;
      margin-top: -20px;
    }

    .flexbox {
      float: right;
      margin-right: 32px;
      padding-top: 0;
      padding-bottom: 0;
      top: -23px;
      position: relative;
      z-index: 10;
    }

    .btn-none {
      left: 8px !important;
    }

    .p-l-60 {
      padding-left: 60px;
    }

    .material-form-field-rtl {
      font-size: 15px;
      position: relative;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: 5px;
      margin-right: 0;
    }

    .input-add-new-contet {
      border: 1px solid #59d3d3;
      font-size: 11px;
      top: 0;
      float: left;
      position: relative;
      padding: 7px 0 0 8px;
      width: calc(100% - 0vw) !important;
      margin: 8px;
      height: 35px;
      margin-left: 0 !important;
      z-index: 1;
    }

    .tabs-container .nav-tabs {
      padding-right: 0;
    }

    .dropdown-mini-rtl {
      position: relative !important;
    }

    .input-group-rtl {
      top: 45px !important;
      margin: 0 auto;
      display: table;
      width: calc(100% - 35px);
      position: absolute;
      background: #fff;
      left: 0;
      right: 0;
    }

    .code {
      margin-right: 20px !important;
    }

    .price-label {
      display: flex;
      margin-top: 0;
      padding-bottom: 90px;
      margin-left: 51px;
      color: #162635 !important;
      text-align: center;
      justify-content: right;
    }

    .btn-position {
      left: auto;
      top: -20px !important;
      z-index: auto;
      right: -40px !important;
    }

    .phone-small-svg {
      position: relative;
      right: 0 !important;
    }

    .social-buttons {
      padding: 8px 6px 4px 13px !important;
    }

    .scannText {
      z-index: auto !important;
      left: auto !important;
      right: 70px !important;
      line-height: 1 !important;
      top: 162px !important;
    }

    .btn-app-img-add {
      right: -83px;
    }

    .img-content {
      top: 60px !important;
      float: none !important;
    }

    .search-icon-m-list {
      position: absolute;
      z-index: 2;
      margin-top: -6px;
      right: 32px;
    }

    .searchbardiv {
      left: 0;
      position: absolute;
      right: -1px !important;
      width: calc(100% - 131px) !important;
    }

    #menuToggle input~#sidenav #nav>ul>li>a {
      text-align: right !important;
    }

    #menuToggle input~#sidenav {
      position: fixed;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      transition: 0.5s;
      transition-delay: 0.3s;
      overflow: hidden;
      z-index: 1;
    }

    .mobile-menu {
      top: -5px;
      right: auto !important;
      left: 50px !important;
    }

    .btn-toppanel-open {
      position: relative !important;
      left: auto !important;
      right: 0;
    }

    .qr-img {
      right: 18px !important;
    }

    .phone-qr_5 {
      right: 26px !important;
    }

    .d-flex-qr-rtl {
      margin: 0 auto;
    }

    .p-r-qr {
      padding-right: 0 !important;
      padding-top: 60px;
      padding-left: 0 !important;
    }

    .p-l {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .activbtnfont {
      right: auto !important;
      left: 0 !important;
      text-align: center !important;
    }

    .dropdown-mini-rtl {
      right: 80% !important;
    }

    .dropdown-mini-qr-rtl {
      right: 75% !important;
    }

    .pull-left-themeMobile-rtl {
      float: none !important;
    }

    .pull-left-QR-rtl {
      float: none !important;
      padding-left: 0;
      padding-right: 0;
    }

    .groups-remove-icon-tag {
      top: 9px !important;
    }

    .padding-left-right {
      padding-right: 10px !important;
      padding-left: 60px !important;
    }

    .groups-remove-icon {
      top: 7px !important;
      position: relative !important;
    }

    .img-div-flex {
      display: flex;
      justify-content: center;
      left: 0 !important;
      position: relative !important;
    }

    .svg-icon {
      height: 20px;
      margin-top: 0 !important;
      margin-right: 0 !important;
      width: 25px;
    }

    .set-price-btn {
      right: -188px !important;
    }

    .p-l-m-l {
      padding-left: 30px !important;
      margin-left: 19px !important;
      margin-right: -23px !important;
    }

    .select-brand-width {
      width: 100% !important;
    }

    .rating {
      margin-right: 10px !important;
    }

    .input-number {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      height: 37px !important;
      width: 100%;
      border-radius: 18px;
      left: 0;
      bottom: 7px !important;
      font-size: 12px;
      max-width: 90px;
      margin-top: 7px !important;
    }

    .input-number input {
      padding-right: 36px !important;
    }

    .switch-cont-createproduct-rtl {
      margin-right: 0;
      left: 15px !important;
      top: 3px !important;
    }

    .zoomIn {
      cursor: zoom-in;
    }

    .input-group-left {
      // right: 60px !important;
      position: relative;
    }

    .material_block_Percentage {
      left: 54px !important;
    }

    .padding-percentage-div {
      left: 45px !important;
    }

    #page-wrapper {
      margin: 0;
    }

    .cursor {
      cursor: pointer;
      position: relative;
      left: 30px;
    }

    .list-group-languages-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: auto;
      width: 80%;
      position: relative;
      left: 36px;
    }

    .card__image {
      position: relative;
      left: 0 !important;
    }

    .card__content {
      padding-right: 41px;
      margin-right: -40px;
    }

    .cursor {
      cursor: pointer;
      position: static !important;
      left: 30px;
    }

    .list-group-menu-leng {
      height: calc(140px + 0.5vw);
      top: 0;
      position: relative;
      right: 0;
      left: 36px;
      width: calc(100% - 4vw);
      display: block;
      margin: 0 auto;
    }

    .topBtnadd {
      position: relative;
      border-radius: 0;
      left: auto !important;
      width: 100%;
      max-width: 100% !important;
      height: 40px;
      line-height: 2;
      z-index: 1;
      margin: 0 auto;
    }

    .select-arrow-rtl-creatproduct-brand {
      right: 94% !important;
      float: none;
      background: none;
    }

    .select-arrow-rtl-creatproduct-state {
      right: 94% !important;
      top: 13px !important;
      float: none;
      background: none;
    }

    .select-arrow-rtl-chart {
      left: 30px !important;
      right: auto !important;
      background: none;
      margin-top: 10px !important;
    }

    .select-arrow-rtl-generalInfo {
      right: 258px !important;
      top: 11px !important;
      background: none;
    }

    .select-arrow-rtl-editable {
      right: 38px !important;
      top: 11px !important;
      position: absolute;
    }

    .input-group-btn-edit-qr-rtl {
      left: 30px !important;
      top: -10px !important;
    }

    input[type="checkbox"]+label {
      padding-right: 6px;
    }

    .img-languages {
      top: 7px !important;
      right: -3px !important;
    }

    .d-flex-end-rtl {
      left: 0;
    }

    .qr-config-btn {
      display: grid !important;
    }

    .baseSvgBtn {
      margin: 0 auto !important;
      top: -10px !important;
      width: 100%;
    }

    .rtl-times {
      margin-left: -26px;
      float: left;
      top: 0px;
      height: 42px;
      z-index: 1;
    }

    .line-Height {
      line-height: 2.6;
      word-break: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 8px;
      width: 100%;
      max-width: 100%;
      padding-top: 7px;
      padding-bottom: 0;
    }

    .add-languages {
      padding: 1px 0 6px 4px !important;
    }

    .shippingFeePercentage-div-centr {
      position: relative;
      left: -25px;
      display: flex;
    }

    .input-add-new-contet-icon {
      right: 0 !important;
      position: relative !important;
      float: left !important;
      top: -33px !important;
    }

    .div-filter {
      position: absolute;
      left: 0 !important;
      top: -5px;
    }

    .blocks-mobile {
      text-align: center !important;
    }

    .pull-none {
      float: none !important;
    }

    table td:before {
      content: attr(data-title);
      float: right !important;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td {
      display: block;
      font-size: 0.8em;
      text-align: right !important;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      -ms-overflow: hidden;
      -moz-overflow: hidden;
      text-overflow: ellipsis;
    }

    .action-btn-svg {
      position: relative;
      top: -3px;
      float: left !important;
    }

    .title {
      justify-content: flex-start !important;
      display: flex;
      align-items: center;
      align-content: center;
      font-size: 15px;
      padding-top: 10px !important;
      padding-right: 21px !important;
    }

    .d-flex-icon {
      display: grid;
      padding-bottom: 26px;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      left: 14px !important;
      position: relative;
      top: 8px;
      grid-gap: 25px;
    }

    .settings-title {
      display: block !important;
      font-size: 15px;
      font-weight: 600;
      color: #6c707b;
    }

    .frameSvgBtn {
      top: -10px !important;
      width: 100%;
      margin: 0 auto !important;
      max-width: 204px;
    }

    .switch {
      width: 55px !important;
    }

    .btn-logo-open {
      right: 300px;
      top: 10px;
    }
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .btn-logo-open {
      right: 300px;
      top: 10px;
    }

    .add-info-btn-mobile {
      right: 50%;
      margin-right: 40px;
    }

    .header-right-employee-rtl {
      float: left !important;
    }

    .input-group-btnn-standard-qr-rtl {
      left: 63px !important;
      top: -11px !important;
    }

    .tabs-container .nav-tabs>li {
      float: left;
    }

    .dropdown-menu-log-rtl {
      left: 9px !important;
    }

    .header a {
      float: left;
    }

    .mor-text-m-b {
      margin-bottom: 30px;
      font-size: 15px;
      font-weight: 700;
    }

    .input-number-decrement,
    .input-number-increment {
      right: 53px !important;
    }

    .searchbtn-menulist-rtl {
      right: -30px !important;
    }

    .blocks-mobile {
      left: auto !important;
      right: 63px !important;
    }

    .input-daterange {
      right: 40px !important;
      left: auto !important;
    }

    .svg-icon-dropdown {
      left: -11px !important;
      z-index: 5;
    }

    .input-qrFrameupadate-rtl {
      width: 100%;
      float: right;
      max-width: 210px;
    }

    .select-arrow-qrcodecreate-rtl {
      float: left;
      position: relative;
      top: -30px;
      transform: rotate(-90deg);
      right: 0;
    }

    .searchbtn-rtl-qr {
      position: relative;
      outline: none;
      z-index: 1;
      color: #fff;
      font-size: 15px;
      right: -135px !important;
      float: left;
      top: -56px !important;
    }

    .custom-dropdown select {
      width: 100%;
      max-width: 300px;
    }

    .tags-display-grid {
      margin-left: 2px;
      margin-right: -6px;
    }

    .p-r-30 {
      padding-right: 30px;
      padding-left: 0;
    }

    .svg-icon-dropdown {
      left: -220px !important;
      z-index: 5;
    }

    .svgStyleTree {
      width: 70% !important;
      margin: 0 auto;
      top: 30px !important;
      position: relative;
    }

    .svgTop {
      width: 80% !important;
    }

    .header-right {
      float: none;
    }

    .p-r-theme-rtl {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .switch input~ :checked~.toggle {
      left: 28px !important;
    }

    .add-fromexist>.svg-icon {
      left: -30px !important;
    }

    .add-fromexist {
      height: 60px;
      width: 100%;
      bottom: 0;
      outline: none;
      padding: 0;
      border-radius: 0;
      position: relative;
      top: 73px;
      display: block;
      margin: 0 !important;
      background: none !important;
      border: 1px solid #d2d2d2 !important;
      max-width: 200px;
      right: 78px;
    }

    .input-group-div {
      z-index: 2;
    }

    .selectedVariables {
      right: 0;
      top: auto !important;
      bottom: -6px !important;
    }

    .calcdiv-set-price-input {
      width: 100%;
      right: 0 !important;
      bottom: 60px !important;
      top: auto !important;
    }

    .product-option-group-rtl {
      display: block;
      padding-right: 50px !important;
      padding-bottom: 0 !important;
      float: right;
      margin-top: -20px;
    }

    .flexbox {
      float: right;
      margin-right: 32px;
      padding-top: 0;
      padding-bottom: 0;
      top: -23px;
      position: relative;
      z-index: 10;
    }

    //   .input-group-addon{
    //     padding: 10px 10px !important;
    // }
    .btn-none {
      left: 8px !important;
    }

    .p-l-60 {
      padding-left: 60px;
    }

    .material-form-field-rtl {
      font-size: 15px;
      position: relative;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: 5px;
      margin-right: 0;
    }

    .input-add-new-contet {
      border: 1px solid #59d3d3;
      font-size: 11px;
      top: 0;
      float: left;
      position: relative;
      padding: 7px 0 0 8px;
      width: calc(100% - 0vw) !important;
      margin: 8px;
      height: 35px;
      margin-left: 0 !important;
      z-index: 1;
    }

    .tabs-container .nav-tabs {
      padding-right: 0;
    }

    .dropdown-mini-rtl {
      position: relative !important;
    }

    .input-group-rtl {
      top: 45px !important;
      margin: 0 auto;
      display: table;
      width: calc(100% - 35px);
      position: absolute;
      background: #fff;
      left: 0;
      right: 0;
    }

    .code {
      margin-right: 20px !important;
    }

    .price-label {
      display: flex;
      margin-top: 0;
      padding-bottom: 90px;
      margin-left: 51px;
      color: #162635 !important;
      text-align: center;
      justify-content: right;
    }

    .btn-position {
      left: auto;
      top: -20px !important;
      z-index: auto;
      right: -40px !important;
    }

    .phone-small-svg {
      position: relative;
      right: 0 !important;
    }

    .social-buttons {
      padding: 8px 6px 4px 13px !important;
    }

    .scannText {
      z-index: auto !important;
      left: auto !important;
      right: 70px !important;
      line-height: 1 !important;
      top: 162px !important;
    }

    .btn-app-img-add {
      right: -83px;
    }

    .img-content {
      top: 60px !important;
      float: none !important;
    }

    .search-icon-m-list {
      position: absolute;
      z-index: 2;
      margin-top: -6px;
      right: 32px;
    }

    .searchbardiv {
      left: 0;
      position: absolute;
      right: -1px !important;
      width: calc(100% - 131px) !important;
    }

    #menuToggle input~#sidenav #nav>ul>li>a {
      text-align: right !important;
    }

    #menuToggle input~#sidenav {
      position: fixed;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      transition: 0.5s;
      transition-delay: 0.3s;
      overflow: hidden;
      z-index: 1;
    }

    .mobile-menu {
      top: -5px;
      right: auto !important;
      left: 50px !important;
    }

    .btn-toppanel-open {
      position: relative !important;
      left: auto !important;
      right: 0;
    }

    .qr-img {
      right: 18px !important;
    }

    .phone-qr_5 {
      right: 26px !important;
    }

    .d-flex-qr-rtl {
      margin: 0 auto;
    }

    .p-r-qr {
      padding-right: 0 !important;
      padding-top: 60px;
      padding-left: 0 !important;
    }

    .p-l {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .activbtnfont {
      right: auto !important;
      left: 0 !important;
      text-align: center !important;
    }

    .dropdown-mini-rtl {
      right: 80% !important;
    }

    .dropdown-mini-qr-rtl {
      right: 75% !important;
    }

    .pull-left-themeMobile-rtl {
      float: none !important;
    }

    .pull-left-QR-rtl {
      float: none !important;
      padding-left: 0;
      padding-right: 0;
    }

    .groups-remove-icon-tag {
      top: 9px !important;
    }

    .padding-left-right {
      padding-right: 10px !important;
      padding-left: 60px !important;
    }

    .groups-remove-icon {
      top: 7px !important;
      position: relative !important;
    }

    .img-div-flex {
      display: flex;
      justify-content: center;
      left: 0 !important;
      position: relative !important;
    }

    .svg-icon {
      height: 20px;
      margin-top: 0 !important;
      margin-right: 0 !important;
      width: 25px;
    }

    .set-price-btn {
      right: -188px !important;
    }

    .p-l-m-l {
      padding-left: 30px !important;
      margin-left: 19px !important;
      margin-right: -23px !important;
    }

    .select-brand-width {
      width: 100% !important;
    }

    .rating {
      margin-right: 10px !important;
    }

    .input-number {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      height: 37px !important;
      width: 100%;
      border-radius: 18px;
      left: 0;
      bottom: 7px !important;
      font-size: 12px;
      max-width: 90px;
      margin-top: 7px !important;
    }

    .input-number input {
      padding-right: 36px !important;
    }

    .switch-cont-createproduct-rtl {
      margin-right: 0;
      left: 15px !important;
      top: 3px !important;
    }

    .zoomIn {
      cursor: zoom-in;
      position: relative !important;
      right: -18px !important;
    }

    .input-group-left {
      // right: 60px !important;
      position: relative;
    }

    .material_block_Percentage {
      left: 54px !important;
    }

    .padding-percentage-div {
      left: 45px !important;
    }

    #page-wrapper {
      margin: 0;
    }

    .navbar-right {
      left: 0 !important;
      right: auto !important;
    }

    .cursor {
      cursor: pointer;
      position: relative;
      left: 30px;
    }

    .list-group-languages-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: auto;
      width: 80%;
      position: relative;
      left: 36px;
    }

    .card__image {
      position: relative;
      left: 0 !important;
    }

    .card__content {
      padding-right: 41px;
      margin-right: -40px;
    }

    .cursor {
      cursor: pointer;
      position: static !important;
      left: 30px;
    }

    .list-group-menu-leng {
      height: calc(140px + 0.5vw);
      top: 0;
      position: relative;
      right: 0;
      left: 36px;
      width: calc(100% - 4vw);
      display: block;
      margin: 0 auto;
    }

    .topBtnadd {
      position: relative;
      border-radius: 0;
      left: auto !important;
      width: 100%;
      max-width: 100% !important;
      height: 40px;
      line-height: 2;
      z-index: 1;
      margin: 0 auto;
    }

    .select-arrow-rtl-creatproduct-brand {
      right: 90% !important;
      float: none;
      background: none;
    }

    .select-arrow-rtl-creatproduct-state {
      right: 90% !important;
      top: 13px !important;
      float: none;
      background: none;
    }

    .select-arrow-rtl-chart {
      left: 30px !important;
      right: auto !important;
      background: none;
      margin-top: 10px !important;
    }

    .select-arrow-rtl-generalInfo {
      right: 258px !important;
      top: 11px !important;
      background: none;
    }

    .select-arrow-rtl-editable {
      right: 38px !important;
      top: 11px !important;
      position: absolute;
    }

    .input-group-btn-edit-qr-rtl {
      left: 30px !important;
      top: -10px !important;
    }

    .img-languages {
      top: 7px !important;
      right: -3px !important;
    }

    .d-flex-end-rtl {
      left: 0;
    }

    .qr-config-btn {
      display: grid !important;
    }

    .baseSvgBtn {
      margin: 0 auto !important;
      top: -10px !important;
      width: 100%;
      // max-width: 240px !important;
    }

    .rtl-times {
      margin-left: -26px;
      float: left;
      top: 0px;
      height: 42px;
      z-index: 1;
    }

    .line-Height {
      line-height: 2.6;
      word-break: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 8px;
      width: 100%;
      max-width: 100%;
      padding-top: 7px;
      padding-bottom: 0;
    }

    .add-languages {
      padding: 1px 0 6px 4px !important;
    }

    .shippingFeePercentage-div-centr {
      position: relative;
      left: -25px;
      display: flex;
    }

    .input-add-new-contet-icon {
      right: 0 !important;
      position: relative !important;
      float: left !important;
      top: -33px !important;
    }

    .div-filter {
      position: absolute;
      left: 0 !important;
      top: -5px;
    }

    .blocks-mobile {
      text-align: center !important;
    }

    .pull-none {
      float: none !important;
    }

    table td:before {
      content: attr(data-title);
      float: right !important;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td {
      display: block;
      font-size: 0.8em;
      text-align: right !important;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      -ms-overflow: hidden;
      -moz-overflow: hidden;
      text-overflow: ellipsis;
    }

    .action-btn-svg {
      position: relative;
      top: -3px;
      float: left !important;
    }

    .title {
      justify-content: flex-start !important;
      display: flex;
      align-items: center;
      align-content: center;
      font-size: 15px;
      padding-top: 10px !important;
      padding-right: 21px !important;
    }

    .d-flex-icon {
      display: grid;
      padding-bottom: 26px;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      left: 14px !important;
      position: relative;
      top: 8px;
      grid-gap: 25px;
    }

    .settings-title {
      display: block !important;
      font-size: 15px;
      font-weight: 600;
      color: #6c707b;
    }

    .frameSvgBtn {
      top: -10px !important;
      width: 100%;
      margin: 0 auto !important;
      max-width: 204px;
    }

    .switch {
      width: 55px !important;
    }
  }

  .settings-title {
    display: block !important;
  }

  .priority-filter {
    position: relative;
    top: 20px;
    font-size: 15px;
    margin-bottom: 31px;
    display: flex !important;
    justify-content: center !important;
  }

  .print-btn {
    width: 100%;
    background: none;
    height: 40px;
    z-index: 1;
    padding-left: 3px;
    padding-top: 5px;
    border-radius: 0;
    float: right;
    padding-right: 70px;
  }

  .print-btn-edit-qr-rtl {
    position: absolute;
    height: 38px;
    margin-left: 0;
  }

  .print-btn-standard-qr-rtl {
    // left: 77px;
    // position: absolute;
    // margin-top: -13px;
    float: none;
  }

  .input-group-btn-edit-qr-rtl {
    left: 60px;
    top: 0;
  }

  // .btn-w-m-edit-rtl {
  //   width: 76%;
  // }

  .btn-position-not {
    top: -46px;
    right: 83px;
  }


  .input-group-m-t-rtl {
    position: relative;
    left: 40px;
  }

  .btn-w-m-standard-rtl {
    width: 60%;
  }

  .div-grid {
    right: auto !important;
  }

  .input-group-btnn-standard-qr-rtl {
    left: 100px;
    top: 1px;
  }

  .m-t-10 {
    padding-left: 17px !important;
  }

  .left-btn {
    left: -70px;
  }

  .right-btn {
    left: 11px !important;
  }

  .m-b-20 {
    margin-left: 0 !important;
  }

  .btn-submit {
    margin-left: 10px !important;
  }

  .select-prinit-count {
    height: 39px !important;
    float: right;
    min-width: auto;
    margin-left: -24px;
  }

  .copypos {
    cursor: pointer;
    margin-right: 10px;
    float: left;
  }

  .h3 {
    color: #fff;
    font-size: 17px;
    padding: 0;
    text-overflow: ellipsis;
    font-weight: 600;
  }

  .searchbtn-rtl-qr {
    position: relative;
    outline: none;
    z-index: 1;
    color: #fff;
    font-size: 15px;
    float: left;
    right: -57px !important;
    bottom: 13px;
  }

  .searchbtn-menulist-rtl {
    position: relative;
    outline: none;
    z-index: 1;
    color: #fff;
    font-size: 15px;
    right: -62px;
    top: -4px !important;
  }

  .menu-group-search {
    right: 32px;
    position: relative;
  }

  .searchbtn-rtl {
    position: relative;
    outline: none;
    z-index: 1;
    color: #fff;
    font-size: 15px;
    right: -55px;
    float: left;
    // bottom: 24px;
  }

  .min-width-th {
    min-width: 111px !important;
  }

  td,
  th {
    min-width: 111px !important;
  }

  table.shoping-cart-table tr td {
    min-width: auto !important;
  }

  .btn-help-hover {
    width: 28px;
    margin-top: 0;
    background: #1b2033;
  }

  .btn-helps {
    cursor: pointer;
    width: 20px;
    position: absolute;
    left: 0;
    right: auto;
    margin-left: 5px;
  }

  .a-rtls-nav {
    float: left;
    width: auto !important;
  }

  .btn-helps-hover-mini {
    display: none;
  }

  .body.mini-navbar .navbar-static-side {
    width: 120px !important;
  }


  .documentation-btn {
    left: auto !important;
    margin-right: 8px !important;
  }

  .checkboxLeft {
    left: 0 !important;
    bottom: 7px;
    width: 125px;
    display: flex;
    justify-content: space-between;
    float: right !important;
  }

  // .header {
  //   height: auto;
  // }

  .header a {
    text-align: right !important;
  }

  .new-pluse {
    right: -15px;
    position: relative;
  }

  .qrGroupText {
    text-align: center;
  }

  .newIcon {
    position: relative;
    right: -10px !important;
    width: 16px;
    font-size: 16px;
    top: 1px !important;
  }

  table td {
    text-align: left;
  }

  .td-number {
    text-align: center !important;
  }

  .checkbox_Left {
    bottom: 15px;
    width: 140px;
    display: flex !important;
    float: right !important;
    flex-direction: row-reverse !important;
    right: 125px !important;
    position: relative;
  }

  .all-btn-grid {
    display: grid;
    margin: 0 auto;
    justify-content: flex-end;
    position: absolute;
    bottom: 25px;
    grid-gap: 14px;
    left: 25px;
  }

  .exportexcel-icon {
    left: 60px;
  }

  .arrow-next-prev {
    transform: scaleX(-1);
  }

  .addLanguage-btn-title {
    left: 18px;
  }

  @media (min-width: 1281px) {
    .mayaccount {
      left: -3px !important;
    }

    .input-group-left {
      right: 44px !important;
    }

    .list-group-languages-grid {
      right: -56px;
    }

    .card__image {
      left: 8px !important;
    }

    .btn-rtl-pluse {
      right: auto;
      left: 0;
    }

    div.card div.card-title h2 {
      text-align: right;
    }
  }

  @media (max-width: 1152px) and (min-width: 864px) {
    .list-group-languages-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      width: 100%;
      position: relative;
      max-width: 220px;
    }
  }

  @media (max-width: 1280px) and (min-width: 720px) {
    .list-group-languages-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      width: 100%;
      right: 0;
      position: relative;
    }

    .tabs-container .nav-tabs>li {
      width: 100%;
      max-width: 100px !important;
    }
  }

  .btn-notif {
    right: 83% !important;
    top: 16px !important;
  }

  .btn-drop-filtr {
    float: left;
  }

  .media__image-rtl {
    float: none !important;
  }

  .fileUpload {
    cursor: pointer;
    position: absolute;
    top: 7px !important;
    left: 40px;
  }

  .fileUpload-hot-rtl {
    top: -42px !important;
  }

  .lang-exchange>i {
    transform: rotate(180deg) !important;
    -webkit-transform: rotate(180deg) !important;
    -moz-transform: rotate(180deg) !important;
    -ms-transform: rotate(180deg) !important;
    -o-transform: rotate(180deg) !important;
  }

  .labele-language-itme {
    font-size: 19px;
    float: right;
    margin-left: 23px;
    margin-top: 14px;
    margin-right: 15px;
  }

  .labele-language-list-itme {
    margin-right: 30px !important;
  }

  // .input-number.input-number {
  //   left: 70px !important;
  // }
  .navbar-right {
    left: 0 !important;
    right: auto !important;
  }

  .navbar-top-links li a {
    z-index: 1;
    top: -1px;
    left: 75px;
  }

  .code {
    text-align: left;
    margin-left: 19px;
  }

  .nav-second-level li a {
    padding: 7px 35px 7px 10px;
  }

  .ibox-title h5 {
    float: right;
  }

  .pull-right {
    float: left !important;
  }

  .pull-left {
    float: right !important;
  }

  // .rtl-logo-element {
  //   position: relative;
  //   top: 14px !important;
  //   left: 36px !important;
  // }
  .ibox-tools {
    float: right;
  }

  .stat-percent {
    float: left;
  }

  .navbar-right {
    left: 0 !important;
    right: auto !important;
  }

  .navbar-top-links li:last-child {
    margin-left: 40px;
    margin-right: 0;
  }

  .minimalize-styl-2 {
    float: right;
    margin: 14px 20px 5px 5px;
  }

  .feed-element>.pull-left {
    margin-left: 10px;
    margin-right: 0;
  }

  .timeline-item .date {
    text-align: left;
  }

  .timeline-item .date i {
    left: 0;
    right: auto;
  }

  .timeline-item .content {
    border-right: 1px solid #e7eaec;
    border-left: none;
  }

  .theme-config {
    left: 0;
    right: auto;
  }

  .spin-icon {
    border-radius: 0 20px 20px 0;
    -webkit-border-radius: 0 20px 20px 0;
    -moz-border-radius: 0 20px 20px 0;
    -ms-border-radius: 0 20px 20px 0;
    -o-border-radius: 0 20px 20px 0;
  }

  .toast-close-button {
    float: left;
  }

  #toast-container>.toast:before {
    margin: auto -1.5em auto 0.5em;
  }

  #toast-container>div {
    padding: 15px 50px 15px 15px;
  }

  .center-orientation .vertical-timeline-icon i {
    margin-left: 0;
    margin-right: -12px;
  }

  .vertical-timeline-icon i {
    right: 50%;
    left: auto;
    margin-left: auto;
    margin-right: -12px;
  }

  .hours {
    position: relative;
    top: -17px;
    left: 20%;
}

  .file-box {
    float: right;
  }

  ul.notes li {
    float: right;
  }

  .chat-statistic,
  .chat-users {
    margin-right: -30px;
    margin-left: auto;
  }

  .dropdown-menu>li>a {
    text-align: right !important;
  }

  .icon-toppanel-dropDown {
    position: relative !important;
    margin-top: 4px !important;
  }

  .b-r {
    border-left: 1px solid #e7eaec;
    border-right: none;
  }

  .badge {
    top: 0 !important;
  }

  .dd-list .dd-list {
    padding-right: 30px;
    padding-left: 0;
  }

  .globes {
    // right: -30px !important;
    position: relative !important;
  }

  .dd-item>button {
    float: right;
  }

  /* Theme config */
  .theme-config-box {
    margin-left: -220px;
    margin-right: 0;
  }

  .theme-config-box.show {
    margin-left: 0;
    margin-right: 0;
  }

  .spin-icon {
    right: 0;
    left: auto;
  }

  .skin-settings {
    margin-right: 40px;
    margin-left: 0;
  }

  .skin-settings {
    direction: ltr;
  }

  .footer.fixed {
    margin-right: 220px;
    margin-left: 0;
  }

  .text-left {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .header a.logo {
    float: right;
    left: 0 !important;
    padding-right: 45px;
  }

  .svg-basket-icon {
    margin-left: 8px;
  }

  // .card__image {
  //   position: relative;
  //   left: -75px;
  // }

  .card__image_draft {
    position: relative;
    left: auto;
  }

  .pull-right-rtl {
    float: left;
  }

  .price-rtl {
    right: 88% !important;
    text-align: center !important;
  }

  .upload-title-btn {
    // right: -1px !important;
    text-align: center !important;
  }

  .attach-svg-group {
    right: 28px !important;
  }

  .p-0 {
    padding-left: 0;
  }

  .main-check-rtl {
    position: absolute;
    top: 16px;
    left: 60px;
    right: auto;
  }

  // .select-arrow {
  //   left: 20px !important;
  //   right: auto !important;
  // }

  .socila-top-label {
    top: 5px;
    position: relative;
    left: -61px;
  }

  .input-group-left-time {
    right: 30px;
    position: relative;
  }

  .uparrowpos {
    top: 0;
    left: -32px !important;
    margin-top: -1px;
    right: auto !important;
  }

  .search-code-remove {
    right: auto;
    left: 5px;
  }

  .downarrowpos {
    top: 10px !important;
    right: auto !important;
    left: -32px !important;
  }

  .d__flex {
    max-width: 100%;
    right: 130px;
    position: relative;
    direction: ltr;
  }

  .d__flex__center {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .icon-wrappers {
    position: relative !important;
    right: 240px !important;
    float: right;
    z-index: 1;
  }

  .action-svg {
    float: left;
  }

  .rtl-times {
    margin-left: -26px;
    float: left;
    top: 0px;
    height: 42px;
    z-index: 1;
  }

  .percentage-input {
    border: 1px solid #58d3d1 !important;
  }

  .line-Height {
    line-height: 1.5;
    word-break: break-word;
    white-space: pre-line;
    padding-right: 16px;
    float: right;
    text-align: right;
  }

  .padding-left-right {
    padding-right: 30px;
    padding-left: 10px;
  }

  .input-add-new-contet-icon {
    left: 0 !important;
  }

  .search-icon-menu-rtl {
    position: absolute;
    right: 35px !important;
    margin-top: -23px !important;
  }

  .search-icon-map-rtl {
    position: absolute;
    left: auto;
    right: 3px;
  }

  .d-flex-end-rtl {
    left: auto;
    margin: 0 auto;
    display: block;
  }

  .facility-svg-icon {
    right: -45px !important;
    height: 45px !important;
    margin: 7px !important;
  }

  // input {
  //   text-align: right;
  //   padding-right: 12px;
  // }
  .add-new {
    left: 7px !important;
    right: auto !important;
  }

  .minilist {
    margin-top: 20px !important;
  }

  input[type="checkbox"]+label:last-child {
    margin-bottom: 17px;
    padding-right: 9px;
  }

  .search-icon-menu-rtl {
    position: absolute;
    right: 35px !important;
    margin-top: -18px;
  }

  .switch-d-flex {
    position: relative;
    top: -47px;
    left: -20px !important;
  }

  table.shoping-cart-table tr td.desc,
  table.shoping-cart-table tr td:first-child {
    text-align: a;
  }

  .input-number-increment {
    bottom: 16px !important;
  }

  .price-number {
    bottom: 20px;
    position: relative;
  }

  .zoomIn {
    cursor: zoom-in;
    position: absolute;
    right: -18px !important;
  }

  .groups-remove-icon-tag {
    right: auto !important;
    left: 10px;
  }

  .p-r-theme-rtl {
    padding-right: 20px !important;
    padding-left: 0 !important;
  }

  .div-save {
    width: 100%;
    display: flex;
  }

  .header-left {
    float: right;
  }

  .m-l-d-hot {
    margin-left: 44px;
  }

  .icon-calendar-line-rtl {
    font-size: 13px;
    margin-left: 8px;
  }

  .icon {
    left: 4px !important;
    margin-left: 0;
    margin-top: 7px;
  }

  .icon-date-hot {
    right: 50%;
    left: auto;
  }

  .loader-p-rtl {
    white-space: pre;
    text-align: center;
  }

  .loader-rtl {
    right: 14.8% !important;
    left: 0 !important;
  }

  .input-daterange {
    left: auto;
    //right: 120px;
  }

  .ordringlsitcalendar--2 {
    right: -30px;
  }

  .ordringlsitcalendar--1,
  .ordringlsitcalendar--2 {
    top: 8px;
    position: relative;
  }

  .input-group-addon-left {
    border-right: 1px solid #e6e6e6 !important;
  }

  .closeImg {
    left: 0 !important;
  }

  .up-btn {
    color: #1b2033;
    margin: -4px;
  }

  .img-content {
    top: 80px;
    float: right;
  }

  .switch-one {
    top: 4px !important;
    margin-left: 11px !important;
    padding-left: 17px;
    right: 12px !important;
  }

  .m-b-rtl {
    left: -260px !important;
  }

  .searchbardiv {
    left: 200px !important;
    z-index: 111;
  }

  .textOverflow {
    position: relative;
    left: 130px !important;
  }

  .fancy a {
    right: -14px !important;
  }

  .menuGroupSearch {
    position: relative !important;
    right: 34px !important;
    margin-top: 10px;
    top: 10px;
  }

  .label {
    text-align: right;
  }

  .input-group-addon:last-child {
    border: 1px solid #e6e6e6 !important
  }

  .buttonspos-costum {
    left: 0 !important;
  }

  .input-add-new-contet-icon-input {
    left: 0 !important;
    right: auto !important;
  }

  .input-add-new-contet-icon-map {
    left: 5px !important;
    right: auto !important;
    top: 3px !important;
  }

  .newpanel {
    margin-left: 10px;
  }

  .leftDate {
    right: -34%;
  }

  .box-title {
    padding: 0 50px !important;
  }

  .btn-choose {
    right: 38px !important;
    top: 9px !important;
  }

  .addLanguage-btn-title>p {
    margin-right: 20px !important;
  }

  [type="checkbox"]:checked+label:after,
  [type="checkbox"]:not(:checked)+label:after {
    top: 21px !important;
  }

  .flex-table tbody {
    overflow: unset !important;
  }

  .p-l-rtl {
    padding-left: 0 !important;
    padding-right: 20px !important;
  }

  .update-Languages {
    cursor: pointer;
    float: left !important;
  }

  .img-languages {
    top: 6px;
    position: relative;
    height: 48px;
    width: 48px;
    right: -10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .small-new {
    bottom: 22px;
  }

  .icon-big-img {
    padding-right: 13px !important;
  }

  .set-price-btn {
    right: calc(190px + 0.5vw);
  }

  .selectedVariables {
    left: 46px !important;
    right: auto;
  }

  .modal-title {
    left: 0 !important;
    text-align: right !important;
  }

  .en-order-rtl {
    left: 72px !important;
    right: auto !important;
  }

  .img-div-flex {
    display: flex;
    justify-content: center;
    left: 0 !important;
    position: relative !important;
  }

  // .colum {
  //   border-right: 1px solid #59d3d3 !important;
  // }
  .background-border-none {
    border-left: none !important;
  }

  .label-color {
    margin-right: 20px !important;
  }

  .activBtn {
    float: left !important;
  }

  .mobile-desktop-SvgIcon {
    float: left;
  }

  .mobile--desktop-- {
    left: 6px !important;
  }

  // .modal-footer {
  //   margin-top: 0;
  //   border: 0;
  //   display: flex;
  //   flex-direction: row-reverse;
  // }

  .modal-footer-rtl {
    margin-top: 0;
    border: 0;
    display: block !important;
  }

  .close {
    float: left;
    right: 0 !important;
  }

  .text-m-0 {
    right: -18px !important;
    top: -5px !important;
  }

  .no-p {
    left: 7px;
    // margin-left: 30px;
  }

  .refresh {
    right: 0 !important;
  }

  // p {
  //     text-align: right;
  // }

  .filtrs-checkouts {
    position: relative;
    left: 214px;
    display: flex;
    flex-direction: row-reverse;
  }

  .dropdown-checkout-rtl {
    left: auto;
    right: 88%;
  }

  .font-weight {
    margin-left: 0 !important;
    margin-right: 11px !important;
  }

  .div-filter {
    position: absolute;
    left: 0 !important;
    top: 2px;
  }

  .imgFlag {
    max-width: 29px;
  }

  .tree-left {
    right: auto !important;
    left: 5px !important;
    margin-left: 4px;
  }

  .flex-table td.sm,
  .flex-table th.sm {
    max-width: none !important;
  }

  .flex-table-tr-m-r {
    padding-left: 5px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
  }

  .rtl-drop {
    padding-right: 0;
  }

  .treeIcon {
    left: 0;
    right: auto;
    margin-left: 8px;
    z-index: 10;
  }

  .fa-angle-right {
    transform: rotate(180deg);
  }

  .fa-angle-down {
    transform: rotate(0);
  }

  .tree-wrappers-deliveryAddres {
    margin-top: 40px;
  }

  .btn-add-deliveryAddres {
    left: 0;
    right: auto;
  }

  .add-childe-icon {
    margin-right: 0;
  }

  .left-navbar-text {
    left: 0 !important;
  }

  .mini-a {
    .body.rtls .mini-a {
      position: relative;
      left: 43px;
    }
  }

  .self-pl-rtl {
    padding-right: 40px;
  }

  .cursor {
    cursor: pointer;
  }

  .img-toppanel {
    top: -9px;
    width: 52px;
    left: -5px;
  }

  .nav-tabs>li>a {
    text-align: right !important;
  }
}

@media (max-width: 992px) {
  .body.rtls {

    .chat-statistic,
    .chat-users {
      margin-right: 0;
    }
  }
}

.body.mini-navbar .navbar-default .nav>li>a>.a-rtls-nav>.btn-helps {
  display: none;
}

.body.body-small.mini-navbar .footer.fixed,
.body.rtls.mini-navbar .footer.fixed {
  margin: 0 70px 0 0;
}

.body.body-small.mini-navbar .footer.fixed,
.body.rtls.mini-navbar.fixed-sidebar .footer.fixed {
  margin: 0;
}

.body.rtls.top-navigation .navbar-toggle {
  float: right;
  margin-left: 15px;
  margin-right: 15px;
}

.body-small.rtls.top-navigation .navbar-header {
  float: none;
}

.body.rtls.top-navigation #page-wrapper {
  margin: 0;
}

.body.rtls.mini-navbar #page-wrapper {
  margin: 0 70px 0 0;
}

.body.rtls.mini-navbar.fixed-sidebar #page-wrapper {
  margin: 0;
}

.body.rtls.body-small.fixed-sidebar.mini-navbar #page-wrapper {
  margin: 0 var(--sidebar-width) 0 0;
}

.body.rtls.body-small.fixed-sidebar.mini-navbar .navbar-static-side {
  width: var(--sidebar-width);
}

.body-small.rtls .navbar-fixed-top {
  margin-right: 30px;
}

.body-small.rtls .navbar-header {
  float: right;
}

.body.rtls .navbar-top-links li:last-child {
  margin-left: 20px;
}

.body.rtls .top-navigation #page-wrapper,
.body.rtls.mini-navbar .top-navigation #page-wrapper,
.body.rtls.mini-navbar.top-navigation #page-wrapper {
  margin: 0;
}

.body.rtls .top-navigation .footer.fixed,
.body.rtls.top-navigation .footer.fixed {
  margin: 0;
}

@media (max-width: 768px) {
  .body.rtls .navbar-top-links li:last-child {
    margin-left: 20px;
  }

  .body-small.rtls #page-wrapper {
    position: inherit;
    margin: 0;
    min-height: 1000px;
  }

  .body-small.rtls .navbar-static-side {
    display: none;
    z-index: 2001;
    position: absolute;
    width: 70px;
  }

  .body-small.rtls.mini-navbar .navbar-static-side {
    display: block;
  }

  .rtls.fixed-sidebar.body-small .navbar-static-side {
    display: none;
    z-index: 2001;
    position: fixed;
    width: var(--sidebar-width);
  }

  .rtls.fixed-sidebar.body-small.mini-navbar .navbar-static-side {
    display: block;
  }
}

// For special ltr supporting plugin
.rtls .ltr-support {
  direction: ltr;
}

.rtls.mini-navbar .nav-second-level,
.rtls.mini-navbar li.active .nav-second-level {
  left: auto;
  right: 70px;
}

.rtls #right-sidebar {
  left: -260px;
  right: auto;
  top: 64px;
}

.rtls #right-sidebar.sidebar-open {
  left: 0;
}