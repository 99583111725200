/*# sourceURL=_base.scss */

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  height: 100%;
  overflow: hidden;
  -ms-overflow: hidden;
  -moz-overflow: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: auto;
  scroll-behavior: smooth;
  touch-action: manipulation;
}

body,
html {
  height: 100%;
  overflow: hidden;
  -ms-overflow: hidden;
  -moz-overflow: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: auto;
  scroll-behavior: smooth;
  touch-action: manipulation;
}

body.full-height-layout #page-wrapper,
body.full-height-layout #wrapper {
  height: 100%;
}

#page-wrapper {
  min-height: 100vh;
}

body.boxed-layout #wrapper {
  background-color: var(--IboxBg);
  max-width: var(--boxed-width);
  margin: 0 auto;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
}

.boxed-layout #wrapper.top-navigation,
.top-navigation.boxed-layout #wrapper {
  max-width: 1300px !important;
}

.block {
  display: block;
}

.clear {
  display: block;
  overflow: hidden;
  -ms-overflow: hidden;
  -moz-overflow: hidden;
}

a {
  cursor: pointer;
}

a:focus,
a:hover {
  text-decoration: none;
}

.border-bottom {
  border-bottom: 1px solid var(--border-color) !important;
}

.font-bold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-italic {
  font-style: italic;
}

.b-r {
  border-right: 1px solid var(--border-color);
}

.hr-line-dashed {
  border-top: 1px dashed var(--border-color);
  color: var(--white);
  background-color: var(--white);
  height: 1px;
  margin: 20px 0;
}

.hr-line-solid {
  border-bottom: 1px solid var(--border-color);
  background-color: rgba(0, 0, 0, 0);
  border-style: solid !important;
  margin-top: 15px;
  margin-bottom: 15px;
}

video {
  width: 100% !important;
  height: auto !important;
}

.gallery > .row > div {
  margin-bottom: 15px;
}

.fancybox img {
  margin-bottom: 5px;
  width: 24%;
}

.modal-header {
  background-color: var(--white) !important;
  border-color: var(--white) !important;
  border-bottom: 2px solid #e3e3e3 !important;
}

.note-editor {
  height: auto !important;
}

.note-editor.fullscreen {
  z-index: 2050;
}

.note-editor.note-frame.fullscreen {
  z-index: 2020;
}

.note-editor.note-frame .note-editing-area .note-editable {
  color: var(--text-color);
  padding: 15px;
}

.note-editor.note-frame {
  border: none;
}

.note-editor.panel {
  margin-bottom: 0;
}

/* MODAL */

.modal-content {
  background-clip: padding-box;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  outline: 0 none;
  position: relative;
}

.modal-dialog {
  z-index: 2200;
}

.modal-body {
  padding: 20px 30px 30px;
}

.inmodal .modal-body {
  background: #f8fafb;
}

.inmodal .modal-header {
  padding: 30px 15px;
  text-align: center;
}

.animated.modal.fade .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.inmodal .modal-title {
  font-size: 26px;
}

.inmodal .modal-icon {
  font-size: 84px;
  color: var(--white);
}

.modal-footer {
  margin-top: 0;
}

/* WRAPPERS */

#wrapper {
  width: 100%;
}

.wrapper {
  padding: 0 20px;
}

.wrapper-content {
  position: relative;
  top: 80px;
}

#page-wrapper {
  padding: 0 15px;
  position: relative !important;
}

@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    //margin: 0 0 0 240px;
    margin: 0 0 0 260px;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }

  .add-pluse {
    height: 25px;
    cursor: pointer;
    outline: none;
    right: 0;
    position: relative;
  }
}

.title-action {
  text-align: right;
  padding-top: 30px;
}

.ibox-content h1,
.ibox-content h2,
.ibox-content h3,
.ibox-content h4,
.ibox-content h5,
.ibox-title h1,
.ibox-title h2,
.ibox-title h3,
.ibox-title h4,
.ibox-title h5 {
  color: var(--white);
}

ol.unstyled,
ul.unstyled {
  list-style: none outside none;
  margin-left: 0;
}

.big-icon {
  font-size: 160px !important;
  color: var(--white);
}

/* FOOTER */

.footer {
  background: none repeat scroll 0 0 var(--white);
  border-top: 1px solid var(--border-color);
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  position: absolute;
  right: 0;
}

.footer.fixed_full {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  background: var(--white);
  border-top: 1px solid var(--border-color);
}

.footer.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  background: var(--white);
  border-top: 1px solid var(--border-color);
  margin-left: var(--sidebar-width);
}

body.body-small.mini-navbar .footer.fixed,
body.mini-navbar .footer.fixed {
  margin: 0 0 0 70px;
}

body.canvas-menu .footer.fixed,
body.mini-navbar.canvas-menu .footer.fixed {
  margin: 0 !important;
}

body.fixed-sidebar.body-small.mini-navbar .footer.fixed {
  margin: 0 0 0 var(--sidebar-width);
}

body.body-small .footer.fixed {
  margin-left: 0;
}

/* PANELS */

.page-heading {
  border-top: 0;
  padding: 0 10px 20px;
  position: relative;
  top: 10px;
}

.panel-heading h1,
.panel-heading h2 {
  margin-bottom: 5px;
}

/* TABLES */

.table-bordered {
  border: 1px solid var(--white);
}

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  background-color: var(--white);
  border-bottom-width: 1px;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #e7e7e7;
}

.table > thead > tr > th {
  border-bottom: 1px solid #dddddd;
  vertical-align: bottom;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-bottom: 1px solid var(--border-color);
  line-height: 1.42857;
  padding: 10px;
  vertical-align: top;
  border-top: 1px solid var(--border-color);
}

/* PANELS */

.panel.blank-panel {
  background: none;
  margin: 0;
}

.blank-panel .panel-heading {
  padding-bottom: 0;
}

.nav-tabs > li > a {
  color: #a7b1c2;
  font-weight: 600;
  padding: 10px 20px 10px 25px;
}

.nav-tabs > li > a:focus,
.nav-tabs > li > a:hover {
  background-color: #e6e6e6;
  color: var(--text-color);
}

.ui-tab .tab-content {
  padding: 20px 0;
}

/* GLOBAL  */

.no-padding {
  padding: 0 !important;
}

.no-borders {
  border: none !important;
}

.no-margins {
  margin: 0 !important;
}

.no-top-border {
  border-top: 0 !important;
}

.ibox-content.text-box {
  padding-bottom: 0;
  padding-top: 15px;
}

.border-left-right {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.border-top-bottom {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.border-left {
  border-left: 1px solid var(--border-color);
}

.border-right {
  border-right: 1px solid var(--border-color);
}

.border-top {
  border-top: 1px solid var(--border-color);
}

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.border-size-sm {
  border-width: 3px;
}

.border-size-md {
  border-width: 6px;
}

.border-size-lg {
  border-width: 9px;
}

.border-size-xl {
  border-width: 12px;
}

.full-width {
  width: 100% !important;
}

.link-block {
  font-size: 12px;
  padding: 10px;
}

.nav.navbar-top-links .link-block a {
  font-size: 12px;
}

.link-block a {
  font-size: 10px;
  color: inherit;
}

body.mini-navbar .branding {
  display: none;
}

img.circle-border {
  border: 6px solid var(--white);
  border-radius: 50%;
}

.branding {
  float: left;
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  padding: 17px 20px;
  text-align: center;
  background-color: var(--navy);
}

.login-panel {
  margin-top: 25%;
}

.icons-box h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.icons-box .infont a i {
  font-size: 25px;
  display: block;
  color: var(--text-color);
}

.icons-box .infont a {
  color: #a6a8a9;
}

.icons-box .infont a {
  padding: 10px;
  margin: 1px;
  display: block;
}

.ui-draggable .ibox-title {
  cursor: move;
}

.breadcrumb {
  background-color: var(--white);
  padding: 0;
  margin-bottom: 0;
}

.breadcrumb > li a {
  color: inherit;
}

.breadcrumb > .active {
  color: inherit;
}

code {
  background-color: #f9f2f4;
  border-radius: 4px;
  color: var(--red);
  font-size: 90%;
  padding: 2px 4px;
  white-space: nowrap;
}

.ibox-height-list {
  height: calc(100vh - 110px);
}

.ibox {
  clear: both;
  overflow: hidden;
  -ms-overflow: hidden;
  -moz-overflow: hidden;
  background: var(--white);
  margin-top: 0;
  padding: 0;
  border-radius: 0 0 5px 5px;
}

.ibox:after,
.ibox:before {
  display: table;
}

.ibox-title {
  background-color: var(--IboxBg);
  border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 4px 10px;
  min-height: 35px;
}

.ibox-content {
  background-color: var(--ibox-title-bg);
  color: inherit;
  padding: 8px 1px 0 0;
  overflow: scroll;
  height: calc(100vh - 165px);
  margin-right: 0;
  overflow: hidden;
  -ms-overflow: hidden;
  -moz-overflow: hidden;
  z-index: 1;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid var(--border-color);
  font-size: 90%;
  background: var(--white);
  padding: 10px 15px;
}

table.table-mail tr td {
  padding: 12px;
}

.table-mail .check-mail {
  padding-left: 20px;
}

.table-mail .mail-date {
  padding-right: 20px;
}

.check-mail,
.star-mail {
  width: 40px;
}

.unread td,
.unread td a {
  font-weight: 600;
  color: inherit;
}

.read td,
.read td a {
  font-weight: normal;
  color: inherit;
}

.unread td {
  background-color: #f9f8f8;
}

.ibox-content {
  clear: both;
}

.ibox-heading {
  background-color: #f3f6fb;
  border-bottom: none;
}

.ibox-heading h3 {
  font-weight: 200;
  font-size: 24px;
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: left;
  color: var(--white);
}

.ibox-title .label {
  float: left;
  margin-left: 4px;
}

.ibox-tools {
  display: block;
  float: none;
  margin-top: -4px;
  position: relative;
  padding: 0;
  text-align: right;
  z-index: 1;
}

.ibox-tools a {
  color: var(--white);
  font-size: 18px;
  padding: 3px;
  cursor: pointer;
  position: relative;
}

.ibox-tools i:hover {
  color: var(--main-color);
  font-weight: 600;
}

.o-groups a:hover {
  background: var(--main-color);
  color: var(--white);
  font-weight: 600;
}

.ibox-tools a.btn-primary {
  color: var(--white);
}

.ibox-tools .dropdown-menu > li > a {
  padding: 4px 10px;
  font-size: 12px;
}

.ibox .ibox-tools.open > .dropdown-menu {
  left: auto;
  right: 0;
}

/* BACKGROUNDS */

.bg-muted,
.gray-bg {
  background: var(--bodyhtmlBg);
}

.white-bg {
  background-color: var(--white);
}

.bg-success,
.blue-bg {
  background-color: var(--blue);
  color: var(--white);
}

.bg-primary,
.navy-bg {
  background-color: var(--navy);
  color: var(--white);
}

.bg-info,
.lazur-bg {
  background-color: var(--main-color);
  color: var(--white);
}

.bg-warning,
.yellow-bg {
  background-color: var(--yellow);
  color: var(--white);
}

.bg-danger,
.red-bg {
  background-color: var(--red);
  color: var(--white);
}

.black-bg {
  background-color: var(--black);
}

.panel-primary {
  border-color: var(--navy);
}

.panel-primary > .panel-heading {
  background-color: var(--navy);
  border-color: var(--navy);
}

.panel-success {
  border-color: var(--blue);
}

.panel-success > .panel-heading {
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.panel-info {
  border-color: var(--main-color);
}

.panel-info > .panel-heading {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: var(--white);
}

.panel-warning {
  border-color: var(--yellow);
}

.panel-warning > .panel-heading {
  background-color: var(--yellow);
  border-color: var(--yellow);
  color: var(--white);
}

.panel-danger {
  border-color: var(--red);
}

.panel-danger > .panel-heading {
  background-color: var(--red);
  border-color: var(--red);
  color: var(--white);
}

.progress-bar {
  background-color: var(--navy);
}

.progress-small,
.progress-small .progress-bar {
  height: 10px;
}

.progress-mini,
.progress-small {
  margin-top: 5px;
}

.progress-mini,
.progress-mini .progress-bar {
  height: 5px;
  margin-bottom: 0;
}

.progress-bar-navy-light {
  background-color: var(--main-color);
}

.progress-bar-success {
  background-color: var(--blue);
}

.progress-bar-info {
  background-color: var(--main-color);
}

.progress-bar-warning {
  background-color: var(--yellow);
}

.progress-bar-danger {
  background-color: var(--red);
}

.panel-title {
  font-size: inherit;
}

.jumbotron {
  border-radius: 6px;
  padding: 40px;
}

.jumbotron h1 {
  margin-top: 0;
}

/* COLORS */

.text-navy {
  color: var(--navy);
}

.text-primary {
  color: inherit;
}

.text-success {
  color: var(--blue);
}

.text-info {
  color: var(--main-color);
}

.text-warning {
  color: var(--yellow);
}

.text-danger {
  color: var(--red);
}

.text-muted {
  color: var(--text-color);
}

.text-white {
  color: var(--white);
}

.simple_tag {
  background-color: #f3f3f4;
  border: 1px solid #e7eaec;
  border-radius: 2px;
  color: inherit;
  font-size: 10px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px 12px;
  display: inline-block;
}

.img-shadow {
  -webkit-box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
  -moz-box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
  box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
}

/* For handle diferent bg color in AngularJS version */

.dashboards\.dashboard_2 nav.navbar,
.dashboards\.dashboard_3 nav.navbar,
.dashboards\.dashboard_4_1 nav.navbar,
.dashboards\.dashboard_5 nav.navbar,
.mailbox\.email_compose nav.navbar,
.mailbox\.email_view nav.navbar,
.mailbox\.inbox nav.navbar,
.metrics nav.navbar,
.metrics\.index nav.navbar {
  background: var(--white);
}

/* For handle diferent bg color in MVC version */

.ComposeEmail .navbar.navbar-static-top,
.Dashboard_2 .navbar.navbar-static-top,
.Dashboard_3 .navbar.navbar-static-top,
.Dashboard_4_1 .navbar.navbar-static-top,
.Dashboard_5 .navbar.navbar-static-top,
.EmailView .navbar.navbar-static-top,
.Inbox .navbar.navbar-static-top,
.Metrics .navbar.navbar-static-top {
  background: var(--white);
}

.light-navbar .navbar.navbar-static-top {
  background-color: var(--white);
}

.modal-backdrop {
  z-index: 2040 !important;
}

.modal {
  z-index: 2050 !important;
}

.spiner-example {
  height: 200px;
  padding-top: 70px;
}

/* MARGINS & PADDINGS */

.p-xxs {
  padding: 5px;
}

.p-xs {
  padding: 10px;
}

.p-sm {
  padding: 15px;
}

.p-m {
  padding: 20px;
}

.p-md {
  padding: 25px;
}

.p-lg {
  padding: 30px;
}

.p-xl {
  padding: 40px;
}

.p-w-xs {
  padding: 0 10px;
}

.p-w-sm {
  padding: 0 15px;
}

.p-w-m {
  padding: 0 20px;
}

.p-w-md {
  padding: 0 25px;
}

.p-w-lg {
  padding: 0 30px;
}

.p-w-xl {
  padding: 0 40px;
}

.p-h-xs {
  padding: 10px 0;
}

.p-h-sm {
  padding: 15px 0;
}

.p-h-m {
  padding: 20px 0;
}

.p-h-md {
  padding: 25px 0;
}

.p-h-lg {
  padding: 30px 0;
}

.p-h-xl {
  padding: 40px 0;
}

.m-xxs {
  margin: 2px 4px;
}

.m {
  margin: 15px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m {
  margin: 15px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-xl {
  margin: 50px;
}

.m-n {
  margin: 0 !important;
}

.m-l-none {
  margin-left: 0;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l {
  margin-left: 15px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-l-xl {
  margin-left: 40px;
}

.m-l-n-xxs {
  margin-left: -1px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.m-l-n-sm {
  margin-left: -10px;
}

.m-l-n {
  margin-left: -15px;
}

.m-l-n-md {
  margin-left: -20px;
}

.m-l-n-lg {
  margin-left: -30px;
}

.m-l-n-xl {
  margin-left: -40px;
}

.m-t-none {
  margin-top: 0;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t {
  margin-top: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-xl {
  margin-top: 40px;
}

.m-t-n-xxs {
  margin-top: -1px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n {
  margin-top: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-t-n-lg {
  margin-top: -30px;
}

.m-t-n-xl {
  margin-top: -40px;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xxs {
  margin-right: 1px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r {
  margin-right: 15px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-r-xl {
  margin-right: 40px;
}

.m-r-n-xxs {
  margin-right: -1px;
}

.m-r-n-xs {
  margin-right: -5px;
}

.m-r-n-sm {
  margin-right: -10px;
}

.m-r-n {
  margin-right: -15px;
}

.m-r-n-md {
  margin-right: -20px;
}

.m-r-n-lg {
  margin-right: -30px;
}

.m-r-n-xl {
  margin-right: -40px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xxs {
  margin-bottom: 1px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.m-b-n-xxs {
  margin-bottom: -1px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-b-n-lg {
  margin-bottom: -30px;
}

.m-b-n-xl {
  margin-bottom: -40px;
}

.space-15 {
  margin: 15px 0;
}

.space-20 {
  margin: 20px 0;
}

.space-25 {
  margin: 25px 0;
}

.space-30 {
  margin: 30px 0;
}

// IMAGES SIZE
.img-sm {
  width: 32px;
  height: 32px;
}

.img-md {
  width: 64px;
  height: 64px;
}

.img-lg {
  width: 96px;
  height: 96px;
}

// BORDER RADIUS
.b-r-xs {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.b-r-sm {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.b-r-md {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.b-r-lg {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.b-r-xl {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

// Fullscreen functions
.fullscreen-ibox-mode .animated {
  animation: none;
}

body.fullscreen-ibox-mode {
  overflow-y: hidden;
}

.ibox.fullscreen {
  z-index: 2030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-bottom: 0;
}

.ibox.fullscreen .collapse-link {
  display: none;
}

.ibox.fullscreen .ibox-content {
  min-height: calc(100% - 48px);
}

// Bootstrap issue in 3.3.0 with modal https://github.com/twbs/bootstrap/issues/9855
// Fix
body.modal-open {
  padding-right: inherit !important;
}

// Fix modal in Safari browser
body.modal-open .wrapper-content.animated {
  -webkit-animation: none;
  -ms-animation-nam: none;
  animation: none;
}

// Fix for modal backdrop with animate.css
body.modal-open .animated {
  animation-fill-mode: initial;
  z-index: inherit;
}

/* Show profile dropdown on fixed sidebar */

.block,
body.mini-navbar.fixed-sidebar .profile-element {
  display: block !important;
}

.profile-element {
  background-color: var(--nav-second-level);
  border-bottom: 1px solid #00000024;
}

body.mini-navbar.fixed-sidebar .nav-header {
  padding: 33px 25px;
}

body.mini-navbar.fixed-sidebar .logo-element {
  display: none;
}

.fullscreen-video .animated {
  animation: none;
}

input:focus {
  outline: none;
}

input {
  width: 100%;
  transition: border 200ms ease-in-out;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 0;
  box-shadow: none;
  margin: 0 18px 9px;
  border: 0;
  font-size: 14px;
  -webkit-appearance: none;
  border: 1px solid var(--main-color);
  cursor: text;
  padding-left: 10px;
  border-radius: 0 !important;
  color: #000 !important;
}

.wrap {
  position: relative;
  margin-bottom: 10px;
}

.placeholder {
  position: absolute;
  font-size: 14px;
  top: 14px;
  color: #848484;
  font-weight: 300;
  transition: top 200ms ease-in-out, font-size 200ms ease-in-out,
    color 200ms ease-in-out;
  pointer-events: none;
}

input:focus {
  border-bottom: 1px solid var(--main-color);
}

input:focus ~ .placeholder {
  top: -5px;
  font-size: 12px;
  color: var(--blue);
}

input:valid ~ .placeholder {
  top: -5px;
  font-size: 12px;
}

// ::-webkit-scrollbar {
//     width: 17px;
//     height: 3px;
// }

// ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
//     border-radius: 5px;
// }

// ::-webkit-scrollbar-thumb {
//     border-radius: 10px 0 0 10px !important;
//     background: #cfcfcf;
// }

[type="file"],
[type="image"] {
  cursor: pointer;
}

[type="file"]::-webkit-file-upload-button {
  -webkit-appearance: button;
  cursor: pointer;
}

[type="file"]::-webkit-file-upload-button {
  -webkit-appearance: button;
}

.bell-anim {
  -webkit-animation: ring 1.5s ease;
  animation: ring 1.5s ease;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(35deg);
  }

  12.5% {
    -webkit-transform: rotate(-30deg);
  }

  25% {
    -webkit-transform: rotate(25deg);
  }

  37.5% {
    -webkit-transform: rotate(-20deg);
  }

  50% {
    -webkit-transform: rotate(15deg);
  }

  62.5% {
    -webkit-transform: rotate(-10deg);
  }

  75% {
    -webkit-transform: rotate(5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
  }

  12.5% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  25% {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }

  37.5% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }

  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  62.5% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  75% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.box-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.not {
  color: var(--black);
  font-size: 15px;
  font-weight: 600;
}

.not_1 {
  color: var(--black);
  font-size: 15px;
  font-weight: 600;
}

.title-top {
  color: var(--white);
  // text-transform: uppercase;
  font-size: 14px;
  padding: 0;
  text-overflow: ellipsis;
  float: left;
  font-weight: 600;
  margin-bottom: 0;
  top: 3px;
  position: relative;
  width: 50%;
}

.title-m {
  // margin: -9px 0 0;
  margin: 4px -15px 0;
}

.back {
  cursor: pointer;
  color: var(--white);
  font-size: 18px;
  top: 10px;
  position: absolute;
}

.back:hover {
  color: var(--white);
}

@media print {
  nav.navbar-static-side {
    display: block;
  }

  body {
    overflow: visible !important;
    -ms-overflow: visible !important;
    -moz-overflow: visible !important;
  }

  #page-wrapper {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .navbar-static-side {
    z-index: 2001;
    position: fixed;
    width: 100%;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }

  .add-pluse {
    height: 25px;
    cursor: pointer;
    outline: none;
    right: 0;
    position: relative;
  }
}

h3 {
  color: var(--white);
  text-transform: uppercase;
  font-size: 14px;
  padding: 0;
  text-overflow: ellipsis;
  font-weight: 600;
  margin-top: -16px;
  left: 18px;
  position: relative;
  width: 5%;
}

.header-a-h {
  height: 5px;
}

.header-right {
  float: right;
}

.header-left {
  float: left;
}

@media screen and (max-width: 500px) {
  .add-pluse {
    height: 25px;
    cursor: pointer;
    outline: none;
    right: 0;
    position: relative;
  }
}

.modal-header {
  padding: 12px 10px 10px;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 5px 3px;
  border-radius: 15px;
  text-align: center;
  font-size: 18px;
  line-height: 1.1;
}

.textareas {
  resize: none;
  // min-height: 100px;
}

.btn-primary.focus,
.btn-primary:focus {
  color: var(--white);
  background-color: var(--main-color);
  border-color: var(--main-color);
}

input:-webkit-autofill,
input:-webkit-autofill:focus textarea:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: #a7a7a7;
  -webkit-box-shadow: 0 0 0 1000px #2f3d4b00 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.btn-none {
  border: none;
  background: none;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: var(--white);
  text-decoration: none;
}

.svg-delete {
  height: 27px;
  cursor: pointer;
  top: 0;
}

.svg-create {
  height: 27px;
  cursor: pointer;
  top: 0;
  margin: 5px;
}

.search-icon {
  position: absolute;
  z-index: 2;
  color: #c5c5c5;
  height: 26px;
  margin-left: 23px;
  margin-top: -18px;
}

@media (min-width: 292px) {
  .add-pluse {
    height: 25px;
    cursor: pointer;
    outline: none;
    right: 0;
    top: 0;
    position: relative;
  }
}

@media (min-width: 1200px) {
  .add-pluse {
    height: 25px;
    cursor: pointer;
    outline: none;
    right: 0;
    position: relative;
  }
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  color: var(--black);
  font-size: 15px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .ibox-content {
    height: calc(100vh - 150px) !important;
  }

  .ibox-height-list {
    height: calc(100vh - 80px) !important;
  }

  // ::-webkit-scrollbar {
  //     width: 4px !important;
  // }

  // .svg-icon {
  //     height: 20px;
  //     margin-top: 8px;
  //     width: 25px;
  // }

  .ibox {
    border: none !important;
  }

  .wrapper-content {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 15px;
    top: 45px !important;
  }

  .modal-dialog {
    top: 0 !important;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
}

.z-index-1 {
  z-index: 1;
}

.d-none {
  display: none !important;
}

.d-grid {
  display: grid;
}

.bg-none {
  background: none;
}

.text-transform-n {
  text-transform: none;
}

.header--padding {
  padding-left: 0;
  z-index: 1;
}

.margin-bottom {
  margin-bottom: 0;
}

.navbar-fixed-top {
  background: #1b2033;
  transition-duration: 0.4s;
  border: 0 !important;
}

.modal-header {
  height: 90px;
  padding-top: 20px !important;
}

.svg-icon-close-modal {
  height: 16px;
  width: 16px;
}

.modal-title {
  font-size: 16px !important;
  color: #0e101a !important;
}

.dropdown-backdrop {
  position: relative !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

//Desebel default eye password microsoft edge
input::-ms-clear,
input::-ms-reveal {
  display: none;
}

select {
  border-radius: 0 !important;
  background: var(--white);
}

.rtl-ir {
  direction: rtl;
}

.ltr-old {
  direction: ltr;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

::selection {
  background: var(--red);
  color: var(--white);
}

.animation-header-down {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.animation-header-up {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .btn-toppanel-open {
    top: 22px !important;
  }
}

.savDefault {
  outline: none;
  margin-top: -10px;
}

.savDefault > img {
  width: 30px;
  height: 27px;
  padding: 3px;
  margin-right: 1px;
}

//
* {
  box-sizing: border-box;
}
.border-none {
  border: 0;
}
.btn-disabled {
  opacity: 0.7;
  pointer-events: none;
}
.p__rightSide {
  padding-right: 0;
}

.p-0 {
  padding: 0 !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}
.mr-5 {
  margin-right: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-content-between {
  justify-content: space-between;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.relative {
  position: relative;
}
.pointer {
  cursor: pointer;
}
.svg-icon {
  height: 25px;
  width: 25px;
}

.svg-icon-big {
  height: 35px;
  width: 35px;
}
.svg-icon-m {
  width: 20px;
  height: 20px;
}
.svg-icon-small {
  width: 15px;
  height: 15px;
}
.basic__wrapper {
  padding: 75px 0 25px 0;
  height: 100vh;
  overflow: hidden;

  .basic__block {
    border-radius: 8px;
    overflow: hidden;
    background: var(--white);
  }
  .basic__content-header {
    position: sticky;
    top: 0;
    background: var(--white);
    padding-bottom: 15px;
    z-index: 1;
  }
  .basic__content {
    padding: 15px;
  }
  .basic__content-scroll {
    height: calc(100vh - 180px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 6px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: var(--main-color);
    }

    &::-webkit-scrollbar-track {
      border-radius: 6px;
      box-shadow: inset 0 0 5px var(--gray);
    }
  }
  .btn {
    height: 40px;
    border: none;
    box-shadow: none;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 6px;
    line-height: 1.5;
  }

  button {
    border: none;
    box-shadow: none;
  }
  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    color: var(--black);
    font-size: 16px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-control {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid var(--main-color);
    border-radius: 2px !important;
    font-weight: normal;
    display: block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    margin: 0;
  }

  .switch__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .switch__content {
      position: relative;
      .switch {
        height: 20px;
        width: 60px;
        border-radius: 30px;
        box-shadow: inset 1px 1px 3px 0px #00000029;
        border-color: #dfdfdf;
        background-color: #eff1f1;
        z-index: 5;
      }
      .switch input {
        height: 20px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 2;
        margin: 0;
        cursor: pointer;
      }
      .toggle {
        background: var(--dark-gray);
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        transition: left 0.2s ease;
        border: 0 !important;
        color: var(--white);
        box-shadow: 1px 1px 3px 0 var(--dark-gray);
        width: 26px;
        height: 26px;
        top: -3px;
      }
      .switch input:checked ~ .toggle {
        left: 0;
        transition: background-color 0.4s ease 0s, left 0.2s ease 0s;
      }

      .switch input ~ :checked ~ .toggle {
        background: var(--main-color);
        left: 35px;
      }

      .switch input:checked {
        z-index: 0;
      }
      .switch label:nth-of-type(1) {
        right: 110px;
        white-space: nowrap;
        position: relative;
        top: 4px;
        color: var(--main-bg);
        font-size: 14px;
      }

      .switch label:nth-of-type(2) {
        position: absolute;
        right: -95%;
        text-align: left;
      }
    }
  }

  .checkbox__item {
    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"] + label {
      position: relative;
      padding-left: 30px;
      color: black;
      cursor: pointer;
      white-space: nowrap;
      line-height: 1.3;
    }

    input[type="checkbox"] + label:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid var(--main-color);
      transition: all 0.12s, border-color 0.08s;
    }

    input[type="checkbox"]:checked + label:before {
      width: 10px;
      left: 6px;
      border-top-color: transparent;
      border-left-color: transparent;
      transform: rotate(45deg);
    }
  }
}

.header {
  height: 50px;
  width: 100%;
  padding: 5px 10px;
  background-color: var(--white);
  border-bottom: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  span {
    line-height: normal;
    display: inline-block;
    padding: 0 5px;
  }
  a {
    color: #1b2033;
  }
  .logo {
    font-weight: 700;
  }

  button {
    background: none;
    border: none;
    padding: 0;
  }

  .header__left-side {
    display: flex;
    align-items: center;
  }

  .header__right-side {
    display: flex;
    align-items: center;

    & > div {
      position: relative;
    }

    button {
      margin-left: 5px;
      .caret {
        padding: 0;
        width: 0;
        height: 0;
        margin-left: 2px;
        display: inline-block;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid \9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
  }
}
.input-daterange-wrapper {
  margin-left: 15px;

  .input-daterange {
    display: flex;
    align-items: center;
    position: relative;

    .fa-minus {
      position: absolute;
      left: calc(50% - 18px);
      font: normal normal normal 16px FontAwesome;
    }
  }
}

.search__wrapper {
  position: relative;

  button {
    background: none;
    border: none;
    padding: 0;
  }

  .search__container {
    font-size: 14px;
    height: 40px;
    width: 30vw;
    min-width: 250px;
    max-width: 500px;
    position: absolute;
    z-index: 1;
    right: 25px;
    top: -5px;
    display: flex;
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--main-bg);
    box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
    border-radius: 4px;
    overflow: hidden;

    .search-input {
      height: 100%;
      border: 0;
      margin: 0;
    }

    .search__button {
      height: 100%;
      border-left: 1px solid var(--main-bg);
    }
  }
}

.filter__content {
  position: relative;
  display: flex;
  align-items: center;

  .dropdown-menu {
    border: 1px solid var(--main-bg);
    position: absolute;
    min-width: 100px;
    max-width: 180px;
    left: unset;
    top: 40px;
    right: 0;
    border-radius: 6px;
    padding: 5px;
    box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);

    &::before {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 3px 14px 10px 0;
      border-color: transparent #d7d7d7 transparent var(--black);
      transform: rotate(-36deg);
      top: -8px;
      right: 15px;
    }

    span {
      font-size: 14px;
    }

    .filteritem {
      cursor: pointer;
      text-align: center;
      padding: 5px 10px;
      width: 100%;
      white-space: nowrap;
      text-transform: capitalize;

      &:hover {
        background-color: var(--gray);
      }
    }

    .filterItemSel {
      background: var(--main-bg) !important;
      color: var(--white);
      border-radius: 4px;
    }

    .sort-icon {
      margin-left: 10px;
    }
  }
}

@media (max-width: 767px) {
  #page-wrapper {
    padding: 0;
  }
  .basic__wrapper {
    padding: 60px 0 80px 0;
    height: 100%;
  }

  .header:not(.header__main) {
    flex-direction: column-reverse;
    height: 80px;
    padding: 10px;

    .header__left-side {
      justify-content: space-between;
      width: 100%;
    }

    .header__right-side {
      justify-content: flex-end;
      width: 100%;
    }
  }
  .p__rightSide {
    padding-right: 15px;
  }
}
