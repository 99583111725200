/*# sourceURL=_skins.scss */

.btn-helps {
    cursor: pointer;
    width: 20px;
    position: absolute;
    margin-right: 9px;
    right: 0;
}

.grid-video {
    display: inline-block;
    margin-right: 0px;
    overflow: auto;
    height: 100%;
    max-height: 290px;
}

.grid-video>iframe {
    margin: 5px;
    box-shadow: 1px 1px 4px 0px #55595a;
    width: 100%;
    max-width: 340px;
}

.modal-header>p {
    font-size: 20px;
}

.modal-header {
 
    border-radius: 7px 7px 0 0;
}

.svg-icon-close-modal {
    height: 16px;
    width: 16px;
}

.close-new {
    opacity: 1;
    outline: none;
    margin-top: -4px;
    top: 3px;
    right: 19px;
    position: relative;
}

.accordion {
    width: 100%;
}

.accordion input {
    display: none;
}

.box {
    position: relative;
    background: white;
    transition: all 0.15s ease-in-out;
}

.box::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
}

header.box {
    background: #00bcd4;
    z-index: 100;
    cursor: initial;
    box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px -2px rgba(0, 0, 0, 0.12), 0 2px 4px -4px rgba(0, 0, 0, 0.24);
}

header .box-title {
    margin: 0;
    font-weight: normal;
    font-size: 16pt;
    color: white;
    cursor: initial;
}

.box-title {
    width: calc(100% - 40px);
    height: 64px;
    line-height: 64px;
    padding: 0 20px;
    display: inline-block;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.box-content {
    width: calc(100% - 5px);
    padding: 30px 20px;
    font-size: 11pt;
    color: rgba(0, 0, 0, 0.54);
    display: none;
}

.box-close {
    position: absolute;
    height: 64px;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    display: none;
}

input:checked+.box {
    height: auto;
    margin: 16px 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.32);
}

input:checked+.box .box-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
}

input:checked+.box .box-content,
input:checked+.box .box-close {
    display: inline-block;
}

.arrows section .box-title {
    padding-left: 44px;
    width: calc(100% - 64px);
}

.arrows section .box-title:before {
    position: absolute;
    display: block;
    content: "\203a";
    font-size: 18pt;
    left: 20px;
    top: -2px;
    transition: transform 0.15s ease-in-out;
    color: rgba(0, 0, 0, 0.54);
}

input:checked+section.box .box-title:before {
    transform: rotate(90deg);
}

.help-title {
    padding-left: 13px;
    font-size: 20px;
    font-weight: 600;
}

.help-text {
    word-break: break-word;
    padding-left: 15px;
    padding-right: 15px;
}

.border-right {
    border-right: 1px solid #ddd;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 30%;
    opacity: 0;
    transition: .3s ease;
    right: 0;
    margin-top: 2px;
    margin-right: -1px;
}

.containers:hover .overlay {
    opacity: 1;
}

.icon {
    font-size: 100px;
    position: absolute;
    left: auto;
    right: 0;
    text-align: center;
    margin-right: 9px;
    margin-top: 7px;
}

.fa-user:hover {
    color: #eee;
}

.left-helps {
    right: 21px;
    margin-top: -1px;
    margin-left: -1px;
    position: absolute;
}

.btn-helps-hover {
    width: 22px;
    margin-top: 1px;
    margin-left: 7px;
}
