/*# sourceURL=_badges_labels.scss */

.label {
    background-color: var(--light-gray);
    color: var(--label-badge-color);
    font-weight: 600;
    padding: 3px 8px;
    text-shadow: none;
}

.nav .label,
.ibox .label {
    font-size: 10px;
}

.badge {
    background-color: var(--light-gray);
    color: var(--label-badge-color); 
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    text-shadow: none;
}

.label-primary,
.badge-primary {
    // background-color: $navy;
    background-color: #59d3d3;
    color: #FFFFFF;
}

.label-success,
.badge-success {
    background-color: var(--blue);
    color: #FFFFFF;
}

.label-warning,
.badge-warning {
    background-color: var(--yellow);
    color: #FFFFFF;
}

.label-warning-light,
.badge-warning-light {
    background-color: var(--yellow);
    color: #ffffff;
}

.label-danger,
.badge-danger {
    background-color: var(--red);
    color: #FFFFFF;
}

.label-info,
.badge-info {
    background-color: var(--main-color);
    color: #FFFFFF;
}

.label-inverse,
.badge-inverse {
    background-color: #262626;
    color: #FFFFFF;
}

.label-white,
.badge-white {
    background-color: #FFFFFF;
    color: #5E5E5E;
}

.label-white,
.badge-disable {
    background-color: #2A2E36;
    color: #8B91A0;
}