@import "assets/styles/style";

@font-face {
  font-family: login;
  ///src: url("/assets/font/Bokonique-Light.otf");
  src: url("/assets/font/BIG-JOHN.otf");
}

@font-face {
  font-family: signinPass;
  src: url("/assets/font/OpenSans-Regular.ttf");
}

@font-face {
  font-family: navlogo;
  src: url("/assets/font/BalooPaaji-Regular.ttf");
}


@media (max-width: 480px) and (min-width: 320px) {
  .modal-backdrop {
    z-index: -1 !important;
  }

}
@media (min-width: 481px) and (max-width: 767px) {
  .modal-backdrop {
    z-index: -1 !important;
  }

}