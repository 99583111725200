/*# sourceURL=_navigation.scss */
//new start
.body.mini-navbar #page__wrapper {
  margin: 0 0 0 100px;
}
.pace-done #page__wrapper {
  transition: all 0.4s;
  z-index: auto;
}
.body.fixed-nav #wrapper #page__wrapper {
  margin-top: 60px;
}
.body.top-navigation.fixed-nav #wrapper #page__wrapper {
  margin-top: 0;
}

.body.canvas-menu.mini-navbar #page__wrapper {
  margin: 0;
}

.body.fixed-sidebar.mini-navbar #page__wrapper {
  margin: 0;
}

.body.body-small.fixed-sidebar.mini-navbar #page__wrapper {
  margin: 0 0 0 var(--sidebar-width);
}
@media (max-width: 767px) {
  .body.mini-navbar #page__wrapper {
    margin: 0;
  }
}
// new end
.nav > li > a {
  color: var(--nav-text-color);
  font-weight: 600;
  padding: 14px 20px 6px 25px;
}

.nav.navbar-right > li > a {
  color: #999c9e;
}

.nav > li.active > a {
  color: var(--nav-text-color);
  font-size: 13px;
  font-weight: 400;
}

.navbar-default .nav > li > a:focus,
.navbar-default .nav > li > a:hover {
  //background-color: darken(var(--nav-bg), 3%);
  color: var(--white);
  background-color: var(--nav-active-color) !important;
  border-radius: 20px 0px 0 20px;
  transition: none !important;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background: #fff;
}

.nav.navbar-top-links > li > a:focus,
.nav.navbar-top-links > li > a:hover {
  background-color: transparent;
}

.nav > li > a i {
  margin-right: 6px;
}

.navbar {
  border: 0;
}

.navbar-default {
  background-color: transparent;
  border-color: var(--nav-bg);
}

.navbar-top-links li {
  display: inline-block;
}

.navbar-top-links li:last-child {
  margin-right: 0;
}

// .body-small .navbar-top-links li:last-child {
//   margin-right: 0;
// }
// .navbar-top-links li a {
//   padding: 20px 10px;
//   min-height: 50px;
// }
.dropdown-menu {
  border: medium none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  display: none;
  float: left;
  font-size: 12px;
  left: 0;
  list-style: none outside none;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
}

.dropdown-menu > li > a {
  border-radius: 3px;
  color: inherit;
  line-height: 25px;
  margin: 4px;
  text-align: left;
  font-weight: normal;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: var(--navy);
  outline: 0;
}

.dropdown-menu > li > a.font-bold {
  font-weight: 600;
}

.navbar-top-links .dropdown-menu li {
  display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
  padding: 3px 20px;
  min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
  white-space: normal;
}

.navbar-top-links .dropdown-alerts,
.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks {
  width: 310px;
  min-width: 0;
}

.navbar-top-links {
  margin-left: 5px;
}

.dropdown-messages {
  margin-left: auto;
}

.navbar-top-links .dropdown-tasks {
  margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
  margin-left: -123px;
}

.navbar-top-links .dropdown-user {
  right: 0;
  left: auto;
}

.dropdown-alerts,
.dropdown-messages {
  padding: 10px;
}

.dropdown-alerts li a,
.dropdown-messages li a {
  font-size: 12px;
}

.dropdown-alerts li em,
.dropdown-messages li em {
  font-size: 10px;
}

.nav.navbar-top-links .dropdown-alerts a {
  font-size: 12px;
}

.nav-header {
  background: var(---nav-bg);
}

.pace-done .nav-header {
  transition: all 0.4s;
}

ul.nav-second-level {
  // background: darken(var(--nav-bg), 3%);
}

.nav > li.active {
  //border-left: 4px solid darken(var(--navy), 2%);
  //  background: darken(var(--nav-bg), 3%);
}

.nav.nav-second-level > li.active {
  border: none;
}

.nav.nav-second-level.collapse[style] {
  height: auto !important;
}

.nav-header a {
  color: #dfe4ed;
}

.nav-header .text-muted {
  text-align: center;
  bottom: 72px;
  float: right;
  position: absolute;
  right: 0;
  font-size: 20px;
  left: 6%;
  color: #fff;
  float: none;
  font-family: "Baloo";
  /* letter-spacing: 2px; */
}

.minimalize-styl-2 {
  font-size: 14px;
  top: 12px;
  position: relative;
}

.navbar-form-custom {
  float: left;
  height: 50px;
  padding: 0;
  width: 200px;
  display: block;
}

.navbar-form-custom .form-group {
  margin-bottom: 0;
}

.nav.navbar-top-links a {
  font-size: 14px;
}

.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  font-size: 13px;
  height: 45px;
  margin: 0;
  z-index: 2000;
  left: 67px;
}

.count-info .label {
  background: var(--red) !important;
  background-color: var(--red) !important;
  border: 2px solid var(--red);
  color: var(--white);
  border-radius: 50px;
  font-weight: bold;
  z-index: 2;
  padding: 5px 5px;
  font-family: sans-serif;
}

.arrow {
  float: right;
}

.fa.arrow:before {
  content: "\f104";
}

.active > a > .fa.arrow:before {
  content: "\f107";
}

.nav-second-level li,
.nav-third-level li {
  border-bottom: none !important;
}

.nav-second-level li a {
  padding: 7px 10px 7px 52px;
}

.nav-third-level li a {
  padding-left: 62px;
}

.nav-second-level li:last-child {
  margin-bottom: 10px;
}

.mini-navbar .nav li:focus > .nav-second-level,
.body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar
  .nav
  li:hover
  > .nav-second-level {
  display: block;
  border-radius: 0 2px 2px 0;
  min-width: 140px;
  height: auto;
}

.body.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  font-size: 12px;
  border-radius: 3px;
}

.fixed-nav .slimScrollDiv #side-menu {
  padding-bottom: 60px;
}

.mini-navbar .nav-second-level li a {
  padding: 10px 10px 10px 15px;
}

// .mini-navbar .nav .nav-second-level {
//   position: absolute;
//   left: 70px;
//   top: 0;
//   background-color: var(--nav-bg);
//   padding: 10px;
//   font-size: 12px;
// }
.mini-navbar .nav .nav-second-level {
  position: relative;
  left: -40px;
  background-color: var(--nav-bg);
  padding: 0;
  font-size: 12px;
  width: 100vh;
  z-index: 1;
  top: 3px;
}

.canvas-menu.mini-navbar .nav-second-level {
  //  background: darken(var(--nav-bg), 3%);
}

.mini-navbar li.active .nav-second-level {
  left: 65px;
}

.navbar-default .special_link a {
  background: var(--navy);
  color: white;
}

.navbar-default .special_link a:hover {
  background: #17987e !important;
  color: white;
}

.navbar-default .special_link a span.label {
  background: #fff;
  color: var(--navy);
}

.navbar-default .landing_link a {
  // background: lighten(var(--navy), 3%);
  color: white;
}

.navbar-default .landing_link a:hover {
  background: var(--navy) !important;
  color: white;
}

.navbar-default .landing_link a span.label {
  background: #fff;
  // color: lighten(var(--navy), 3%);
}

.logo-element {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  padding: 18px 0;
  outline: none;
  cursor: pointer;
}

.pace-done #page-wrapper,
.pace-done .footer,
.pace-done .nav-header,
.pace-done .navbar-static-side,
.pace-done li.active {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: auto;
}

.navbar-fixed-top {
  background: #fff;
  transition-duration: 0.4s;
  border-bottom: 1px solid #e7eaec !important;
}

.navbar-fixed-top,
.navbar-static-top {
  background: var(--gray);
}

.fixed-nav #wrapper {
  margin-top: 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none;
  border-color: #dddddd #dddddd rgba(0, 0, 0, 0);
  border-bottom: var(--gray);
  border-image: none;
  border-style: solid;
  border-width: 1px;
  color: #555555;
  cursor: default;
}

.nav.nav-tabs li {
  background: none;
  border: none;
}

.body.fixed-nav #wrapper #page-wrapper,
.body.fixed-nav #wrapper .navbar-static-side {
  margin-top: 60px;
}

.body.top-navigation.fixed-nav #wrapper #page-wrapper {
  margin-top: 0;
}

.body.fixed-nav.fixed-nav-basic .navbar-fixed-top {
  left: 220px;
}

.body.fixed-nav.fixed-nav-basic.mini-navbar .navbar-fixed-top {
  left: 70px;
}

.body.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar .navbar-fixed-top {
  left: 0;
}

.body.fixed-nav.fixed-nav-basic #wrapper .navbar-static-side {
  margin-top: 0;
}

.body.fixed-nav.fixed-nav-basic.body-small .navbar-fixed-top {
  left: 0;
}

.body.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar.body-small
  .navbar-fixed-top {
  left: 220px;
}

.fixed-nav .minimalize-styl-2 {
  margin: 14px 5px 5px 15px;
}

.body-small .navbar-fixed-top {
  margin-left: 0;
}

.body.mini-navbar .navbar-static-side {
  width: 100px;
  position: fixed;
  // z-index: 1;
}

.body.mini-navbar .nav-label,
.body.mini-navbar .navbar-default .nav li a span,
.body.mini-navbar .profile-element {
  display: none;
}

.body.canvas-menu .profile-element {
  display: block;
}

.body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav-second-level {
  display: block;
}

.body.mini-navbar .navbar-default .nav > li > a {
  font-size: 16px;
}

.body.mini-navbar .logo-element {
  font-size: 21px;
  position: relative;
  line-height: 0.2;
  top: 7px;
  left: 19px;
  height: 77px;
  width: 40px;
}

.img-circle-mobile {
  width: 100%;
  height: auto;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: table;
  border-radius: 0;
  top: -20px;
}

.body.canvas-menu .logo-element {
  display: none;
}

.body.mini-navbar .nav-header {
  padding: 0;
}

.body.canvas-menu .nav-header {
  padding: 33px 25px;
}

.body.mini-navbar #page-wrapper {
  margin: 0 0 0 90px;
}

.body.canvas-menu.mini-navbar .footer,
.body.fixed-sidebar.mini-navbar .footer {
  margin: 0 !important;
}

.body.canvas-menu.mini-navbar #page-wrapper,
.body.canvas-menu.mini-navbar .footer {
  margin: 0;
}

.body.canvas-menu .navbar-static-side,
.body.fixed-sidebar .navbar-static-side {
  position: fixed;
  width: var(--sidebar-width);
  z-index: 2001;
  height: 100%;
}

.body.fixed-sidebar.mini-navbar .navbar-static-side {
  width: 0;
}

.body.fixed-sidebar.mini-navbar #page-wrapper {
  margin: 0;
}

.body.body-small.fixed-sidebar.mini-navbar #page-wrapper {
  margin: 0 0 0 var(--sidebar-width);
}

.body.body-small.fixed-sidebar.mini-navbar .navbar-static-side {
  width: var(--sidebar-width);
}

.canvas-menu.mini-navbar .nav li:focus > .nav-second-level,
.fixed-sidebar.mini-navbar .nav li:focus > .nav-second-level {
  display: block;
  height: auto;
}

.body.fixed-sidebar.mini-navbar
  .navbar-default
  .nav
  > li
  > .nav-second-level
  li
  a {
  font-size: 12px;
  border-radius: 3px;
}

.body.canvas-menu.mini-navbar
  .navbar-default
  .nav
  > li
  > .nav-second-level
  li
  a {
  font-size: 13px;
  border-radius: 3px;
}

.canvas-menu.mini-navbar .nav-second-level li a,
.fixed-sidebar.mini-navbar .nav-second-level li a {
  padding: 10px 10px 10px 15px;
}

.canvas-menu.mini-navbar .nav-second-level,
.fixed-sidebar.mini-navbar .nav-second-level {
  position: relative;
  padding: 0;
  font-size: 13px;
}

.canvas-menu.mini-navbar li.active .nav-second-level,
.fixed-sidebar.mini-navbar li.active .nav-second-level {
  left: 0;
}

.body.canvas-menu.mini-navbar .navbar-default .nav > li > a,
.body.fixed-sidebar.mini-navbar .navbar-default .nav > li > a {
  font-size: 13px;
}

.body.canvas-menu.mini-navbar .nav-label,
.body.canvas-menu.mini-navbar .navbar-default .nav li a span,
.body.fixed-sidebar.mini-navbar .nav-label,
.body.fixed-sidebar.mini-navbar .navbar-default .nav li a span {
  display: inline;
}

.body.canvas-menu.mini-navbar .navbar-default .nav li .profile-element a span {
  display: block;
}

.canvas-menu.mini-navbar .nav-second-level li a,
.fixed-sidebar.mini-navbar .nav-second-level li a {
  padding: 7px 10px 7px 52px;
}

.canvas-menu.mini-navbar .nav-second-level,
.fixed-sidebar.mini-navbar .nav-second-level {
  left: 0;
}

.body.canvas-menu nav.navbar-static-side {
  z-index: 2001;
  background: #2f353a;
  height: 100%;
  position: fixed;
  display: none;
}

.body.canvas-menu.mini-navbar nav.navbar-static-side {
  display: block;
  width: 220px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .count-info .label {
    background: var(--red) !important;
    background-color: var(--red) !important;
    border: 2px solid var(--red);
    color: var(--white);
    border-radius: 50px;
    font-weight: bold;
    z-index: 2;
    padding: 5px 5px;
    font-family: sans-serif;
  }
  .body.mini-navbar .logo-element {
    justify-content: space-around;
    height: 77px;
    display: flex !important;
    width: auto !important;
    position: static !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .count-info .label {
    background: var(--red) !important;
    background-color: var(--red) !important;
    border: 2px solid var(--red);
    color: var(--white);
    border-radius: 50px;
    font-weight: bold;
    z-index: 2;
    padding: 5px 5px;
    font-family: sans-serif;
  }
  .body.mini-navbar .logo-element {
    justify-content: space-around;
    height: 77px;
    display: flex !important;
    width: auto !important;
    position: static !important;
  }
}
