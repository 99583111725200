/*# sourceURL=_media.scss */

// Standart resolution//
@media (min-width: 1281px) {
  .flex-table td,
  .flex-table th {
    font-size: 12px;
  }

  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    font-size: 11px;
  }
}

@media (max-width: 1366px) and (min-width: 768px) {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    font-size: 12px;
  }

  p,
  b,
  span,
  label {
    font-size: 12px;
  }

  h2 {
    font-size: 15px;
  }

  .flex-table td,
  .flex-table th {
    font-size: 10px;
  }
}

@media (max-width: 1360px) and (min-width: 768px) {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    font-size: 12px;
  }

  p,
  b,
  span,
  label {
    font-size: 12px;
  }

  h2 {
    font-size: 15px;
  }

  .flex-table td,
  .flex-table th {
    font-size: 10px;
  }
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    font-size: 12px;
  }

  p,
  b,
  span,
  label {
    font-size: 12px;
  }

  h2 {
    font-size: 15px;
  }

  .flex-table td,
  .flex-table th {
    font-size: 10px;
  }
}

@media (max-width: 1280px) and (min-width: 960px) {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    font-size: 12px;
  }

  p,
  b,
  span,
  label {
    font-size: 12px;
  }

  h2 {
    font-size: 15px;
  }

  .flex-table td,
  .flex-table th {
    font-size: 10px;
  }
}

@media (max-width: 1280px) and (min-width: 800px) {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    font-size: 12px;
  }

  p,
  b,
  span,
  label {
    font-size: 12px;
  }

  h2 {
    font-size: 15px;
  }

  .flex-table td,
  .flex-table th {
    font-size: 10px;
  }
}

@media (max-width: 1280px) and (min-width: 768px) {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    font-size: 12px;
  }

  p,
  b,
  span,
  label {
    font-size: 12px;
  }

  h2 {
    font-size: 15px;
  }

  .flex-table td,
  .flex-table th {
    font-size: 10px;
  }

  .btn {
    line-height: 0;
    font-size: 12px;
    height: 30px;
  }
}

@media (max-width: 1280px) and (min-width: 720px) {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    font-size: 12px;
  }

  p,
  b,
  span,
  label {
    font-size: 12px;
  }

  h2 {
    font-size: 15px;
  }

  .flex-table td,
  .flex-table th {
    font-size: 10px;
  }
}

@media (max-width: 1152px) and (min-width: 864px) {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    font-size: 12px;
  }

  p,
  b,
  span,
  label {
    font-size: 12px;
  }

  h2 {
    font-size: 15px;
  }

  .flex-table td,
  .flex-table th {
    font-size: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0;
    min-height: 100vh;
  }

  .navbar-static-side {
    z-index: 2001;
    position: absolute;
    width: var(--sidebar-width);
  }

  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #page-wrapper {
    position: inherit;
    // margin: 0 0 0var(--sidebar-width) !important;
    margin: 0 0 0 240px;
    min-height: 100vh;
  }

  .navbar-static-side {
    z-index: 2001;
    position: absolute;
    width: var(--sidebar-width);
  }

  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 0;
    min-height: 100vh;
  }

  .body-small .navbar-static-side {
    display: none;
    z-index: 2001;
    position: absolute;
    width: 70px;
  }

  .body-small.mini-navbar .navbar-static-side {
    display: block;
  }

  .lock-word {
    display: none;
  }

  .navbar-form-custom {
    display: inline-block;
  }

  .navbar-header {
    display: inline;
    float: left;
  }

  .sidebar-panel {
    z-index: 2;
    position: relative;
    width: auto;
    min-height: 100% !important;
  }

  .sidebar-content .wrapper {
    padding-right: 0;
    z-index: 1;
  }

  .fixed-sidebar.body-small .navbar-static-side {
    display: none;
    z-index: 2001;
    position: fixed;
    width: var(--sidebar-width);
  }

  .fixed-sidebar.body-small.mini-navbar .navbar-static-side {
    display: block;
  }

  .ibox-tools {
    float: none;
    text-align: right;
    display: block;
  }

  .navbar-static-side {
    display: none;
  }

  body:not(.mini-navbar) {
    -webkit-transition: background-color 500ms linear;
    -moz-transition: background-color 500ms linear;
    -o-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
  }
}

@media (max-width: 350px) {
  .timeline-item .date {
    text-align: left;
    width: 110px;
    position: relative;
    padding-top: 30px;
  }

  .timeline-item .date i {
    position: absolute;
    top: 0;
    left: 15px;
    padding: 5px;
    width: 30px;
    text-align: center;
    border: 1px solid var(--border-color);
    background: #f8f8f8;
  }

  .timeline-item .content {
    border-left: none;
    border-top: 1px solid var(--border-color);
    padding-top: 10px;
    min-height: 100px;
  }

  .nav.navbar-top-links li.dropdown {
    display: inline-block;
  }

  .ibox-tools {
    float: none;
    text-align: left;
    display: inline-block;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .body-small.mini-navbar .navbar-static-side {
    display: block;
    width: 100% !important;
    z-index: 99999;
  }
}
